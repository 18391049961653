import { ApiSortDirection, PaginatedRequest } from '../../../models/pagination';
import { finalize } from 'rxjs/operators';
import { PaginatedResultBaseDatasource } from '../../../services/paginated-base.datasource';
import { CaregiverDto } from '../../../models/caregiver-dto';
import { CaregiverManagementDashboardService } from '../../../services/caregiver-management-dashboard.service';
import { CurrentSelections } from '../../../models/current-selections';

export class CaregiverPerformanceGridDatasource extends PaginatedResultBaseDatasource<CaregiverDto> {
  constructor(private caregiverManagementDashboardService: CaregiverManagementDashboardService) {
    super();
  }

  loadList(filters: CurrentSelections, pagination: Partial<PaginatedRequest>): void {
    const mergedPagination = {
      page: 0,
      pageSize: 40,
      sortBy: 'caregiver',
      sortDirection: ApiSortDirection.Ascending,
      ...pagination,
    };
    this.isLoadingSubject.next(true);
    this.caregiverManagementDashboardService
      .getList(filters, mergedPagination)
      .pipe(
        finalize(() => {
          this.isLoadingSubject.next(false);
        })
      )
      .subscribe((res) => {
        this.paginatedResponseSubject.next(res);
      });
  }
}
