import { Component, OnInit } from '@angular/core';
import { FilterDrawerService } from '../../../services/filter-drawer.service';

@Component({
  selector: 'app-filter-drawer-header',
  templateUrl: './filter-drawer-header.component.html',
  styleUrls: ['./filter-drawer-header.component.scss']
})
export class FilterDrawerHeaderComponent {

  constructor(public filterDrawerService: FilterDrawerService) { }

}
