<div class="tardiness-display" *ngIf="tardyMinutesAverage !== null && tardyMinutesAverage !== undefined && tardyMinutesAverageTrend && tardyMinutesAverageTrend.length > 0; else noData">
  <div class="tardy-minutes-average">
    {{ tardyMinutesAverage | number: '1.0-0' }}
  </div>
  <div *ngIf="filterService?.defaultDateRangeSelected" class="tardy-minutes-sparkline-container">
    <svg
          viewBox="0 0 40 12"
          width="40"
          height="12"
          class="chart"
          xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        fill="none"
        stroke-width="1.5"
        stroke="#5f727e"
        [attr.points]="data"
      />
      <polyline
        fill="none"
        stroke-width="1"
        stroke-dasharray="2"
        stroke="#5f727e"
        [attr.points]="referenceLine"
      />
    </svg>
  </div>
</div>
<ng-template  #noData>
  <div class="tardiness-display">{{ '-' }}</div>
</ng-template>

