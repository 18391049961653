import { Component } from '@angular/core';
import { LineChartSettingsService } from '../line-chart.settings.service';
import {ReferralsSubmittedSeriesService} from "./referrals-submitted-series.service";

@Component({
  selector: 'app-referrals-submitted-chart',
  templateUrl: './referrals-submitted-chart.component.html',
  styleUrls: ['./referrals-submitted-chart.component.scss'],
})
export class ReferralsSubmittedChartComponent {
  constructor(public referralsSubmittedSeriesService: ReferralsSubmittedSeriesService, public settings: LineChartSettingsService) {
    this.settings = Object.assign({}, this.settings
      .configureYAxisTitle('Referrals')
      .configureYAxisMin((min) => {
        return 0;
      })
      .configureTooltip((val: number, opts?: any): string => {
        if (val === null || val === undefined) return 'No Data';
        return `${val} referrals`
      }));
  }
}
