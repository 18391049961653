import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, tap } from 'rxjs';
import { PaginatedRequest, PaginatedResponse } from '../models/pagination';
import { CaregiverDto } from '../models/caregiver-dto';
import { getGridOptionsAsHttpParams } from '../utils/ai-paging-sorting.utils';
import { CurrentSelections } from '../models/current-selections';
import { CurrentSelectionsBuilder } from './current-selections.builder';
import { saveAs } from 'file-saver';

@Injectable({providedIn: 'root'})
export class CaregiverManagementDashboardService {
  private url = `${environment.apiURL}/caregiverperformancedashboard`;

  constructor(private http: HttpClient) {}

  public getList = (
    filters: CurrentSelections,
    pagination: PaginatedRequest
  ): Observable<PaginatedResponse<CaregiverDto>> => {
    const paramMap = CurrentSelectionsBuilder.newBuilder(filters).buildAsParamMap();
    const params = getGridOptionsAsHttpParams(paramMap, pagination);
    return this.http.get<PaginatedResponse<CaregiverDto>>(
      `${this.url}/caregiverperformance`, {params});
  }


  public getListCsv = (
    filters: CurrentSelections,
    pagination: PaginatedRequest
    ) => {
      const filterParamMap = CurrentSelectionsBuilder.newBuilder(filters).buildAsParamMap();
      const params = getGridOptionsAsHttpParams(filterParamMap, pagination);
      return this.http
      .post(`${this.url}/caregiverperformance/csv?` + params, "", {
        responseType: 'text',
    })
    .pipe(
      tap((res) => {
        const blob = new Blob([res.toString()], { type: 'text/csv' });
        saveAs(blob, 'CaregiverPerformanceExport.csv');
      })
    );
  };
}
