<div class="mx-auto caregiver-search-box">
  <form [formGroup]="caregiverSearchFormGroup" class="caregiver-name-search-form">
    <mat-form-field
      appearance="fill"
      class="caregiver-name-search-form-field"
    >

      <input matInput formControlName="caregiverNameSearch" placeholder="Search caregivers by first name or last name" data-cy="caregiver-search">
      <button class="mat-input-icon mat-input-icon-search" *ngIf="!searchValue || searchValue === ''" mat-button matPrefix>
        <mat-icon>search</mat-icon>
      </button>
      <button class="mat-input-icon" data-cy="clear-button" *ngIf="searchValue && searchValue !== ''" mat-button matPrefix
              aria-label="Clear" (click)="resetForm()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
</div>
