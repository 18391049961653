import {
	animate,
	state,
	style,
	transition,
	trigger,
	AnimationTriggerMetadata
} from '@angular/animations';
import { CarouselItemPositionEnum } from './carousel-item-position.enum';

export const carouselAnimations: {
	readonly translateCarouselItem: AnimationTriggerMetadata;
} = {
	translateCarouselItem: trigger('translateCarouselItem', [
		state(
			`${CarouselItemPositionEnum.center}, ${CarouselItemPositionEnum.void}`,
			style({ transform: 'none', opacity: 1 })
		),
		state(
			CarouselItemPositionEnum.left,
			style({ transform: 'translate3d(-100%, 0, 0)', minHeight: '1px', opacity: 0 })
		),
		state(
			CarouselItemPositionEnum.right,
			style({ transform: 'translate3d(100%, 0, 0)', minHeight: '1px', opacity: 0 })
		),
		transition(
			`${CarouselItemPositionEnum.left} <=> ${CarouselItemPositionEnum.center},
			 ${CarouselItemPositionEnum.right} <=> ${CarouselItemPositionEnum.center}`,
			animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)')
		)
	])
};
