import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CurrentSelection} from 'src/app/models/current-selection';
import {AgencyTagService} from 'src/app/services/agencytag.service';
import {FilterService} from 'src/app/services/filter.service';
import { SelectDataSource } from 'src/app/models/select';

@Component({
  selector: 'app-filter-turnover-risk',
  templateUrl: './filter-turnover-risk.component.html',
  styleUrls: ['./filter-turnover-risk.component.scss']
})
export class FilterTurnoverRiskComponent implements OnInit {
  turnoverRiskOptions: SelectDataSource[] = [
    {label: 'High', value: '3'},
    {label: 'Elevated', value: '2'},
    {label: 'Guarded', value: '1'},
    {label: 'Low', value: '0'},
  ]
  formControl = new FormControl([]);

  constructor(private agencyTagService: AgencyTagService,
              private filterService: FilterService) {
  }

  ngOnInit(): void {
    this.subscribeToFilterSelectionChanges();
  }

  updateTurnoverRiskFilter(): void {
    const selectedValues = this.formControl.value
      .map(v => new CurrentSelection(v, this.turnoverRiskOptions.find(t => t.value === v)?.label ?? v));
    this.filterService.addMultiValuedFilter('turnoverRiskStratification', selectedValues);
  }

  private subscribeToFilterSelectionChanges(): void {
    this.filterService.currentSelectionsChange$.subscribe(() => {
      const currentSelections = this.filterService.currentSelections.turnoverRiskStratification;
      if (!currentSelections) {
        this.formControl.reset([]);
      } else {
        this.formControl.patchValue(currentSelections.map(cs => cs.filterValue));
      }
    });
  }
}