import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { deleteCookie, getCookie, setCookie } from '../utils/cookie.utils';
import { map } from 'rxjs/operators';
import { RedirectService } from './redirect.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private redirectService: RedirectService) {
  }

  authorize(): Observable<string> {
    return this
      .http
      .get(environment.refreshURL + getCookie('access_token'),
        {withCredentials: true})
      .pipe(
        map((result: {access_token: string}) => {
          this.setAccessToken(result.access_token);
          return result.access_token;
        })
      );
  }

  logout(): void {
    deleteCookie('access_token');
    this._isAuthenticated$.next(false);
    this.redirectService.redirect(window,environment.loginRedirectURL);
  }

  getAccessToken(): string {
    return this.getAccessTokenFromUrl() || getCookie('access_token');
  }

  setAccessToken(token: string): void {
    setCookie('access_token', token);
    this._isAuthenticated$.next(true);
  }

  get isAuthenticated$(): Observable<boolean> {
    if (getCookie('access_token')) {
      this._isAuthenticated$.next(true)
    }
    return this._isAuthenticated$;
  }

  private getAccessTokenFromUrl(): string {
    const params = new URLSearchParams(window.location.search);
    return params.get('token');
  }
}
