import { Component, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { TeamEngageService } from '../services/team-engage-service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-team-engage-dashboard',
  templateUrl: './team-engage-dashboard.component.html',
  styleUrls: ['/team-engage-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamEngageDashboardComponent implements AfterViewInit{
  private iframeContainerSelector: string = '.ava-iframe-container';
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private teamEngageService: TeamEngageService) {
  }

  ngAfterViewInit(): void {
    // cannot immediately attach iframe upon initial load of the SDK, only
    // attaches iframe after we know its initialization has been completed
    this.teamEngageService.sdkInitialized$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.teamEngageService.attachIframe(this.iframeContainerSelector);
    })

    // subsequent page loads will attach iframe immediately since SDK has been loaded
    if(this.teamEngageService.isSdkInitialized()){
      this.teamEngageService.attachIframe(this.iframeContainerSelector);
    }
  }

  ngOnDestroy(): void{
    this.teamEngageService.detachIframe(this.iframeContainerSelector);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}