import { ParamMap } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { PaginatedRequest } from '../models/pagination';

/*
* Front end utilities for adapting filters and requests
* for use with AI.Core's PagedFilteredRequest
* */
export const getGridOptionsAsHttpParams = (
  paramMap: ParamMap,
  pagination: PaginatedRequest
): HttpParams => {
  const gridOptions = getGridOptions(paramMap, pagination);
  return toHttpParams(gridOptions);
}

export const convertParamMapToHttpParams = (paramMap: ParamMap): HttpParams => {
  const params = {
    filter: convertParamMaptoFilterString(paramMap)
  };
  return toHttpParams(params);
}

const convertParamMaptoFilterString = (paramMap: ParamMap): string => {
  let filterString = '';
  if (!paramMap || paramMap.keys.length < 1) {
    return filterString;
  }
  const keys = paramMap.keys;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const andString = filterString.length === 0 ? '' : ' and ';
    const operator = getOperatorFromKey(key);
    const currentKey = trimKeySuffix(key);
    let currentSelections = paramMap.getAll(key);
    if (
      currentSelections &&
      currentSelections.length > 0 &&
      currentSelections[0] === ''
    ) {
      currentSelections = [];
    }
    if (currentSelections.length < 1) {
      continue;
    }

    filterString += andString;
    filterString += `${currentKey} ${operator} ${currentSelections.join(
      ','
    )}`;
  }
  return filterString;
}

const toHttpParams = (tableOptions: GridOptions): HttpParams => {
  return Object.getOwnPropertyNames(tableOptions).reduce(
    (p, key) => p.set(key, tableOptions[key]),
    new HttpParams()
  );
}

const trimKeySuffix = (key: string) => {
  const delimiterIndex = key.indexOf('~');
  if (delimiterIndex < 0) {
    return key;
  }
  return key.slice(0, delimiterIndex);
}

const getGridOptions = (
  paramMap: ParamMap,
  pagination: PaginatedRequest
): GridOptions => {
  const sortDirection = pagination.sortDirection === 'desc' ? '-' : '';
  const sortColumn = pagination.sortBy === '' ? '' : pagination.sortBy;
  return {
    filter: convertParamMaptoFilterString(paramMap),
    sort: `${sortDirection}${sortColumn}`,
    pageNumber: pagination.page,
    pageSize: pagination.pageSize,
  };
}

 const getOperatorFromKey = (key: string) => {
  if (key.endsWith('~Start')) {
    return OperatorEnum.greaterThanOrEquals;
  }

  if (key.endsWith('~End')) {
    return OperatorEnum.lessThanOrEquals;
  }

  if (key.endsWith('~Search')) {
    return OperatorEnum.like;
  }

  return OperatorEnum.contains;
}

enum OperatorEnum {
  contains = 'incl',
  equals = 'eq',
  greaterThan = 'gt',
  lessThan = 'lt',
  greaterThanOrEquals = 'ge',
  lessThanOrEquals = 'le',
  like = 'like',
}

class GridOptions {
  sort?: string;
  filter: string;
  pageSize?: number;
  pageNumber?: number;
}
