import { Component, OnInit } from '@angular/core';
import { ChangeIndicator, Direction } from '../../../models/change-indicator';
import {ReferralsSubmittedSeriesService} from "../referrals-submitted-chart/referrals-submitted-series.service";

@Component({
  selector: 'app-referrals-submitted-kpi',
  templateUrl: './referrals-submitted-kpi.component.html',
  styleUrls: ['./referrals-submitted-kpi.component.scss']
})
export class ReferralsSubmittedKpiComponent implements OnInit {
  improved: boolean = false;
  steady: boolean = false;
  worsened: boolean = false;

  changeIndicator: ChangeIndicator;
  constructor(private referralsSubmittedSeriesService: ReferralsSubmittedSeriesService) {
  }

  ngOnInit() {
    this.referralsSubmittedSeriesService.mostRecentChangeIndicator$.subscribe(changeIndicator => {
      this.changeIndicator = changeIndicator;
      this.initDefaultChangeIndicators();
      switch(changeIndicator.direction) {
        case Direction.up:
          this.improved = true;
          break;
        case Direction.steady:
          this.steady = true;
          break;
        case Direction.down:
          this.worsened = true;
          break;
        case Direction.none:
          break;
        default:
          throw new Error(`no state is set for kpi change indicator ${changeIndicator.direction}`);
      }
    });
  }

  private initDefaultChangeIndicators(): void {
    this.worsened = false;
    this.steady = false;
    this.improved = false;
  }
}
