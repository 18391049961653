export class GraphSeries {
  date: Date;
  value: number;
}

export enum SeriesName {
  agency = 'Agency Avg.',
  corporation = 'Corporation Avg.',
  wellSkyRegional = 'WellSky Regional Avg.',
  agencyFiltered = 'Agency Avg. (filtered)',
  pointsEarned = 'Earned',
  pointsRedeemed = 'Redeemed'
}

export enum LineSeriesMetric {
  tardiness = 'tardiness',
  shiftsCancelled = 'shiftsCancelled',
  turnover = 'turnover',
  pointsEarnedRedeemed = 'pointsEarnedRedeemed',
  referralsSubmitted = 'referralsSubmitted',
  goalCompletionRate = 'goalCompletionRate'
}
