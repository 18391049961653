import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {ChartSeries, TurnoverRiskService} from './turnover-risk.service';
import {BarChartSettingsService} from '../bar-chart.settings.service';
import {ChartComponent} from 'ng-apexcharts';
import {FilterService} from "../../../services/filter.service";
import {CurrentSelection} from "../../../models/current-selection";
import {combineLatest, firstValueFrom} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {CurrentSelections} from "../../../models/current-selections";

const StratificationBarColors = ['#7DC380', '#F2C46B', '#F29C38', '#F44236'];

@Component({
  selector: 'app-turnover-risk-chart',
  templateUrl: './turnover-risk-chart.component.html',
  styleUrls: ['./turnover-risk-chart.component.scss'],
  providers: [BarChartSettingsService]
})
export class TurnoverRiskChartComponent implements OnInit {
  @ViewChild('turnoverRiskChart') turnoverRiskChart: ChartComponent;

  settings: BarChartSettingsService;

  constructor(
    public turnoverRiskService: TurnoverRiskService,
    private settingsService: BarChartSettingsService,
    private filterService: FilterService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    combineLatest([this.turnoverRiskService.series$])
      .pipe(debounceTime(100))
      .subscribe(([series]) => this.updateSettings(series));
  }

  handleRiskSelection = (selectedCategory: number) => {
    const stratification = this.settings.xAxis.categories.length - 1 - selectedCategory;
    const currentSelections = this.filterService.currentSelections.turnoverRiskStratification ?? []
    const currentlySelectedStratifications = currentSelections.map(r => parseInt(r.filterValue));
    let newSelections: CurrentSelection[];
    if (currentlySelectedStratifications.includes(stratification)) {
      // If the given stratification was already selected, remove it from current filter selections
      const idx = currentlySelectedStratifications.indexOf(stratification);
      newSelections = [
        ...currentSelections.slice(0, idx),
        ...currentSelections.slice(idx + 1)
      ]
    } else {
      // If the given stratification was not selected, add it to current filter selections.
      newSelections = [
        ...currentSelections,
        new CurrentSelection(stratification.toString(), this.settings.xAxis.categories[stratification])
      ]
    }

    // Ensures that updates will be seen by change detection mechanism, since this event originates from outside of Angular.
    this.zone.run(() => {
      this.filterService.addMultiValuedFilter('turnoverRiskStratification', newSelections);
      firstValueFrom(this.turnoverRiskService.series$).then(s => this.updateSettings(s));
    });
  };

  private updateSettings(series: ChartSeries[]) {
    const filteredStratifications = (this.filterService.currentSelections.turnoverRiskStratification ?? [])
      .map(s => parseInt(s.filterValue));
    if (series && series[0].data.length > 0) {
      const seriesData = series[0].data;
      const maxValue = Math.max(...seriesData);
      let tickAmount = 4;
      if (maxValue == 0) {
        tickAmount = 1;
      } else if (maxValue < 4) {
        tickAmount = maxValue;
      }
      this.settings = Object.assign({}, this.settingsService
        .configureYAxisLabel('Caregivers')
        .configureCategories(['High', 'Elevated', 'Guarded', 'Low'])
        .configureBarColors(StratificationBarColors
          .map((c, stratification) => filteredStratifications.length === 0
              || filteredStratifications.includes(stratification) ? c : '#d0d0d0'
          )
          .reverse()
        )
        .configureXAxisTickAmount(tickAmount)
        .configureTooltip((val: number, opts?: any) => {
          if (val === null || val === undefined) return 'No Data';
          return `${val}`
        })
        .configureBarClickHandler(this.handleRiskSelection)
      );
      this.turnoverRiskChart?.updateOptions(this.settings);
    }
  }
}
