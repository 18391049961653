<div class="risk-factors-popover">
  <p [style.color]="turnoverRiskStratificationColors[caregiver.turnoverRiskStratification]">
    <strong>{{turnoverRiskStratificationDescriptions[caregiver.turnoverRiskStratification]}}</strong>
  </p>
  <ng-container *ngIf="factors.length > 0">
    <p><strong>Top Risk Factors</strong></p>
    <ol>
      <li *ngFor="let factor of factors">
        {{ factor }}
      </li>
    </ol>
  </ng-container>
  <ng-container *ngIf="factors.length === 0">
    <p><strong>No Risk Factors</strong></p>
  </ng-container>
</div>
