import { combineLatest, fromEvent, Observable, of, ReplaySubject, startWith } from 'rxjs';
import { ChangeIndicator, Direction } from '../models/change-indicator';
import { GraphSeries, LineSeriesMetric, SeriesName } from '../models/graph-series';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FilterService } from './filter.service';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AtlasResponseWrapper } from '../models/atlas-response-wrapper';
import { CurrentSelectionsBuilder } from './current-selections.builder';
import { convertParamMapToHttpParams } from '../utils/ai-paging-sorting.utils';

export class LineChartServiceBase {
  mostRecentChangeIndicator$: ReplaySubject<ChangeIndicator> = new ReplaySubject<ChangeIndicator>();
  mostRecentChangeIndicatorPointsEarned$: ReplaySubject<ChangeIndicator> = new ReplaySubject<ChangeIndicator>();
  mostRecentChangeIndicatorPointsRedeemed$: ReplaySubject<ChangeIndicator> = new ReplaySubject<ChangeIndicator>();
  series$: ReplaySubject<{name: string, data: {x: string, y: number}[]}[]> = new ReplaySubject<{name: string, data: {x: string, y: number}[]}[]>();
  loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  resize$: ReplaySubject<Event> = new ReplaySubject<Event>();
  private readonly _seriesNames: SeriesName[];
  private readonly _url = `${environment.apiURL}/graphSeries`;
  private _series: {name: string, data: {x: string, y: number}[]}[] = [];

  protected constructor(private http: HttpClient, private filterService: FilterService, seriesNames: SeriesName[], protected metric: LineSeriesMetric) {
    this._seriesNames = seriesNames;
    this._series = this.initSeries();
    this.series$.next(this._series);
    this.subscribeToFilterServiceChanges();
    this.subscribeToWindowResize();
  }

  private subscribeToWindowResize(): void {
    fromEvent(window, 'resize')
      .pipe((debounceTime(500)))
      .subscribe(this.resize$);
  }

  private initSeries(): {name: string, data: {x: string, y: number}[]}[] {
    return this._seriesNames
      .filter(sn => this.filterService.currentSelectionsAsChipDisplay.length > 0 || sn !== SeriesName.agencyFiltered)
      .map(sn => ({
        name: sn,
        data: []
      }));
  }

  private subscribeToFilterServiceChanges(): void {
    this.filterService.currentSelectionsChange$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => combineLatest([
          this.getAgencyGraphSeries(),
          this.getCorporationGraphSeries(),
          this.getWellSkyGraphSeries(),
          this.getAgencyFilteredGraphSeries(),
          this.getPointsEarnedSeries(),
          this.getPointsRedeemedSeries(),
          this.resize$.pipe(startWith(null))
        ])
      )
    ).subscribe(([agencyResponse, corporationResponse, wellSkyResponse, agencyFilteredResponse, pointsEarnedResponse, pointsRedeemedResponse,
      resize]) => {
      this.updateSeries([agencyResponse, corporationResponse, wellSkyResponse, agencyFilteredResponse, pointsEarnedResponse, pointsRedeemedResponse]);
      this.updateChangeIndicator([agencyResponse, pointsEarnedResponse, pointsRedeemedResponse]);
      this.loading$.next(false);
    });
  }

  private updateSeries([agencyResponse, corporationResponse, wellSkyResponse, agencyFilteredResponse, pointsEarnedResponse,
    pointsRedeemedResponse]: AtlasResponseWrapper<GraphSeries[]>[]): void {
    this.initSeries();

    this._series = this.mapGraphSeriesToApexApi(agencyResponse.result, SeriesName.agency);
    this._series = this.mapGraphSeriesToApexApi(corporationResponse.result, SeriesName.corporation);
    this._series = this.mapGraphSeriesToApexApi(wellSkyResponse.result, SeriesName.wellSkyRegional);
    this._series = this.mapGraphSeriesToApexApi(agencyFilteredResponse.result, SeriesName.agencyFiltered);
    this._series = this.mapGraphSeriesToApexApi(pointsEarnedResponse.result, SeriesName.pointsEarned);
    this._series = this.mapGraphSeriesToApexApi(pointsRedeemedResponse.result, SeriesName.pointsRedeemed);
    this.series$.next(this._series);
  }

  private updateChangeIndicator([agencyResponse, pointsEarnedResponse, pointsRedeemedResponse]: AtlasResponseWrapper<GraphSeries[]>[]): void {
    const changeIndicator = this.getChangeIndicatorFromSeries(agencyResponse);
    const pointsEarnedChangeIndicator = this.getChangeIndicatorFromSeries(pointsEarnedResponse);
    const pointsEarnedRedeemedIndicator = this.getChangeIndicatorFromSeries(pointsRedeemedResponse);
    this.mostRecentChangeIndicator$.next(changeIndicator);
    this.mostRecentChangeIndicatorPointsEarned$.next(pointsEarnedChangeIndicator);
    this.mostRecentChangeIndicatorPointsRedeemed$.next(pointsEarnedRedeemedIndicator);
  }

  private getChangeIndicatorFromSeries(agencyResponse: AtlasResponseWrapper<GraphSeries[]>): ChangeIndicator {
    const graphSeries = agencyResponse.result.filter(d => d.value !== null && d.value !== undefined);
    let result = {
      direction: Direction.none,
      value: undefined
    };
    if (graphSeries.length < 1) {
      return result;
    }
    const lastIndex = graphSeries.length - 1;
    const lastValue = graphSeries[lastIndex].value;
    result.value = lastValue;

    if (graphSeries.length < 2) {
      return result;
    }
    const penultimateIndex = graphSeries.length - 2;
    const penultimateValue = graphSeries[penultimateIndex].value;

    const delta = lastValue - penultimateValue;

    if (delta > 0) {
      result.direction = Direction.up;
    } else if (delta === 0) {
      result.direction = Direction.steady;
    } else if (delta < 0) {
      result.direction = Direction.down;
    }
    return result;
  }

  private mapGraphSeriesToApexApi(graphSeriesList: GraphSeries[], seriesName: String): {name: string, data: {x: string, y: number}[]}[] {
    const currentValueIndex = this._series.findIndex(ts => ts.name === seriesName);
    if (currentValueIndex === -1) {
      return this._series;
    }

    // if the point's value is undefined then it will not connect the other
    // points in the series
    // if the point's value is null it will connect the other points in the series
    const newValues = graphSeriesList.map(sd => ({
      x: this.mapDateToTextDisplay(sd.date),
      y: sd.value === undefined ? null : sd.value
    }));

    const result = [...this._series];
    result[currentValueIndex].data = newValues;
    return result;
  }

  private getTimeScale(): string {
    if (!this.filterService.startDate) return;
    if (!this.filterService.endDate) return;
    const diffTime = Math.abs(this.filterService.endDate.valueOf() - this.filterService.startDate.valueOf());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const timeRangeDiffInWeeks = diffDays / 7;
    if (timeRangeDiffInWeeks < 2) {
      return 'day';
    }

    if (timeRangeDiffInWeeks >= 13) {
      return 'month'
    }
    return 'week';
  }

  private mapDateToTextDisplay(date: Date): string {
    const timeScale = this.getTimeScale();

    switch (timeScale) {
      case 'week':
        return this.formatAsWeek(date);
      case 'day':
        return this.formatAsDay(date);
      case 'month':
        return this.formatAsMonth(date);
      default:
        console.warn(`No format specified for type ${timeScale}`);
    }
  }

  private formatAsWeek(date: Date): string {
    const startDate = new Date(date);
    const endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 6);

    return `${startDate.getMonth() + 1}/${startDate.getDate()} - ${endDate.getMonth() + 1}/${endDate.getDate()}`
  }

  private formatAsDay(date: Date): string {
    return date.toLocaleString('default', {month: 'numeric', day: '2-digit'});
  }

  private formatAsMonth(date: Date): string {
    return date.toLocaleDateString('default', {month: 'numeric', year: '2-digit',})
  }

  private getAgencyGraphSeries(): Observable<AtlasResponseWrapper<GraphSeries[]>> {
    if (this._seriesNames.findIndex(sn => sn === SeriesName.agency) === -1) {
      return of({
        result: [],
        statusCode: 200
      });
    }
    const paramMap = CurrentSelectionsBuilder
      .newBuilder(this.filterService.currentSelections)
      .pick(['startDate', 'endDate'])
      .buildAsParamMap();
    const params = convertParamMapToHttpParams(paramMap)
      .set('source', this.metric);
    return this.http.get<AtlasResponseWrapper<GraphSeries[]>>(
      `${this._url}`, {params});
  }

  private getAgencyFilteredGraphSeries(): Observable<AtlasResponseWrapper<GraphSeries[]>> {
    if (this._seriesNames.findIndex(sn => sn === SeriesName.agencyFiltered) === -1) {
      return of({
        result: [],
        statusCode: 200
      });
    }
    const paramMap = CurrentSelectionsBuilder
      .newBuilder(this.filterService.currentSelections)
      .buildAsParamMap();
    const params = convertParamMapToHttpParams(paramMap)
      .set('source', this.metric);
    return this.http.get<AtlasResponseWrapper<GraphSeries[]>>(
      `${this._url}`, {params});
  }

  private getCorporationGraphSeries(): Observable<AtlasResponseWrapper<GraphSeries[]>> {
    if (this._seriesNames.findIndex(sn => sn === SeriesName.corporation) === -1) {
      return of({
        result: [],
        statusCode: 200
      });
    }
    const paramMap = CurrentSelectionsBuilder
      .newBuilder(this.filterService.currentSelections)
      .pick(['startDate', 'endDate'])
      .buildAsParamMap();
    const params = convertParamMapToHttpParams(paramMap)
      .set('source', this.metric);
    return this.http.get<AtlasResponseWrapper<GraphSeries[]>>(
      `${this._url}`, {params});
  }

  private getWellSkyGraphSeries(): Observable<AtlasResponseWrapper<GraphSeries[]>> {
    if (this._seriesNames.findIndex(sn => sn === SeriesName.wellSkyRegional) === -1) {
      return of({
        result: [],
        statusCode: 200
      });
    }
    const paramMap = CurrentSelectionsBuilder
      .newBuilder(this.filterService.currentSelections)
      .pick(['startDate', 'endDate'])
      .buildAsParamMap();
    const params = convertParamMapToHttpParams(paramMap)
      .set('source', this.metric);
    return this.http.get<AtlasResponseWrapper<GraphSeries[]>>(
      `${this._url}`, {params});
  }

  private getPointsEarnedSeries(): Observable<AtlasResponseWrapper<any[]>> {
    if (this._seriesNames.findIndex(sn => sn === SeriesName.pointsEarned) === -1) {
      return of({
        result: [],
        statusCode: 200
      });
    }
    const paramMap = CurrentSelectionsBuilder
      .newBuilder(this.filterService.currentSelections)
      .pick(['startDate', 'endDate'])
      .buildAsParamMap();
    const params = convertParamMapToHttpParams(paramMap)
      .set('source', 'PointsEarned');
    return this.http.get<AtlasResponseWrapper<GraphSeries[]>>(
      `${this._url}`, {params});
  }
  private getPointsRedeemedSeries(): Observable<AtlasResponseWrapper<any[]>> {
    if (this._seriesNames.findIndex(sn => sn === SeriesName.pointsRedeemed) === -1) {
      return of({
        result: [],
        statusCode: 200
      });
    }
    const paramMap = CurrentSelectionsBuilder
      .newBuilder(this.filterService.currentSelections)
      .pick(['startDate', 'endDate'])
      .buildAsParamMap();
    const params = convertParamMapToHttpParams(paramMap)
      .set('source', 'PointsRedeemed');
    return this.http.get<AtlasResponseWrapper<GraphSeries[]>>(
      `${this._url}`, {params});
  }
}
