<div class="display-table-row-group">
	<span class="high-hosp-risk-display">
	{{ showDefaultDisplay ? highHospRiskPercentDefaultDisplay : (highHospRiskPercent | percent) }}
	</span>
	<br/>
	<div class="progress-bar-container" *ngIf="showProgressBar">
		<div class="progress-bar-background"></div>
		<div
			class="progress-bar"
			[ngStyle]="{ width: widthPercent }"
		></div>
	</div>
</div>