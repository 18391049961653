import {
  CaregiverDto,
  TurnoverRiskStratificationColors,
  TurnoverRiskStratificationDescriptions
} from "../../../../models/caregiver-dto";
import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";

@Component({
  selector: 'app-turnover-risk-factors-popover',
  templateUrl: './turnover-risk-factors-popover.component.html',
  styleUrls: ['./turnover-risk-factors-popover.component.scss']
})
export class TurnoverRiskFactorsPopoverComponent implements OnChanges {
  @Input() caregiver: CaregiverDto;

  factors: string[] = [];

  turnoverRiskStratificationDescriptions = TurnoverRiskStratificationDescriptions;
  turnoverRiskStratificationColors = TurnoverRiskStratificationColors;

  ngOnChanges(changes: SimpleChanges): void {
   if (changes['caregiver']) {
     this.factors = this.getTopTurnoverRiskFactors(changes['caregiver'].currentValue);
   }
  }

  private getTopTurnoverRiskFactors(cg: CaregiverDto): string[] {
    if (!cg.turnoverRiskFactors) {
      return [];
    }
    return cg.turnoverRiskFactors
      .sort((a, b) => a.position - b.position)
      .slice(0, 5) // Keep top 5 factors
      .map(f => f.name);
  }
}
