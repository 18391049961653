import { TardyMinuteDay } from './tardy-minute-day';

export interface CaregiverTurnoverRiskFactorDto {
  name: string;
  position: number;
}

export interface CaregiverDto {
  caregiverId: number;
  caregiver: string;
  tenureMonths: number;
  avgPayRate: number;
  clients: number;
  shiftsCompleted: number;
  avgDailyMileage: number;
  avgDailyMileageArrow: string;
  shiftCancelPercent?: number;
  missedClockInPercent?: number;
  missedClockOutPercent?: number;
  avgShiftLength?: number;
  tardyMinutesAverage?: number;
  tardyMinutesAverageTrend: TardyMinuteDay[];
  fcssShifts: number;
  fcssRequests: number;
  hoursWorked: number;
  hoursWanted: number;
  clientPreferenceRate: number;
  highHospRiskPercent?: number;
  turnoverRiskScore?: number;
  turnoverRiskStratification?: number;
  turnoverRiskFactors?: CaregiverTurnoverRiskFactorDto[];
  avaDaysLoggedIn?: number;
  avaPointsEarned?: number;
  avaPointsRedeemed?: number;
  avaSpotPointsReceived?: number;
  avaReferralsSubmitted?: number;
  avaGoalsCompleted?: number;
  avaGoalsStarted?: number;
  avaGoalCompletionRate?: number;
}

export const TurnoverRiskStratificationDescriptions: Record<number, string> = {
  0: "Low",
  1: "Guarded",
  2: "Elevated",
  3: "High",
};

export const TurnoverRiskStratificationColors: Record<number, string> = {
  0: "#7DC380",
  1: "#F2C46B",
  2: "#F29C38",
  3: "#F44236",
};
