import { Injectable } from '@angular/core';
import {
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexPlotOptions, ApexStates,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from 'ng-apexcharts';

@Injectable()
export class BarChartSettingsService {

  public configureYAxisLabel(title: string): BarChartSettingsService {
    this.xAxis.title.text = title;
    return this;
  }

  public configureCategories(categories: string[]): BarChartSettingsService{
    this.xAxis.categories = categories;
    return this;
  }

  public configureBarColors(colors: string[]): BarChartSettingsService{
    this.colors = colors;
    return this;
  }

  public configureYAxisTickFormat(formatterFn: (val: number, opts?: any) => string): BarChartSettingsService {
    this.yAxis.labels.formatter = formatterFn;
    return this;
  }

  public configureTooltip(formatterFn: (val: number, opts?: any) => string): BarChartSettingsService {
    this.toolTip.y['formatter'] = formatterFn;
    return this;
  }

  public configureYAxisMin(minimum: number | ((min: number) => number)): BarChartSettingsService {
    this.yAxis.min = minimum;
    return this;
  }

  public configureYAxisMax(maximum: number | ((max: number) => number)): BarChartSettingsService {
    this.yAxis.max = maximum;
    return this;
  }

  public configureXAxisTickAmount(tickAmount: number): BarChartSettingsService {
    this.xAxis.tickAmount = tickAmount;
    return this;
  }

  public configureBarClickHandler(f: (selectedCategory: number) => void) {
    this.chart.events.dataPointSelection = (_event, _chartContext, config) => {
      f(config.dataPointIndex);
    }
    return this;
  }

  public chart: ApexChart = {
    height: 235,
    type: 'bar',
    toolbar: {
      show: false
    },
    events: {},
    animations: {
      enabled: false
    }
  };
  public plotOptions: ApexPlotOptions = {
    bar:{
        horizontal: true,
        distributed: true
    }
  };
  public colors: string[] = ['#FF0000', '#FFA500', '#FFFF00', '#008000']
  public xAxis: ApexXAxis = {
    type: 'category',
    tickPlacement: "on",
    labels: {
      formatter: function (val) {
        return parseInt(val).toFixed(0);
      }
    },
    categories: [],
    title:{
        text: 'title'
    }
  };
  public toolTip: ApexTooltip = {
    y: {
        formatter: undefined,
        title: {
          formatter(seriesName: string): string {
            return seriesName;
          }
        }
      }
  };
  public dataLabels: ApexDataLabels = {
    enabled: false
  };
  public legend: ApexLegend = {
    position: 'top',
    showForSingleSeries: true,
    showForNullSeries: false,
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    },
    onItemHover: {
      highlightDataSeries: false
    },
    markers: {
      radius: 0,
      offsetY: 2,
      onClick: undefined
    }
  };
  public markers: ApexMarkers = {
    size: [4, 0, 0],
    colors: ['#fff', undefined, undefined],
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeColors: ['#97449C', '#2A83C8', '#7AD1FB'],
    hover: {
      sizeOffset: 2
    }
  };
  public yAxis: ApexYAxis = {
    show: true,
    decimalsInFloat: 0,
    tickAmount: undefined,
    min: undefined,
    max: undefined,
    forceNiceScale: false,
    title: {
      text: '',
      rotate: -90,
      style: {
        color: '#3D5463',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Roboto'
      }
    },
    labels: {
      minWidth: 48,
      maxWidth: 66,
      formatter: undefined,
    }
  };
  public grid: ApexGrid = {
    borderColor: '#f1f1f1',
    position: 'back'
  };
  public states: ApexStates = {
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        // Selection styling is handled by CSS
        type: 'none'
      }
    },
    hover: {
      filter: {
        // Hover styling is handled by CSS
        type: 'none'
      }
    }
  };
}
