import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterService } from 'src/app/services/filter.service';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, combineLatest, switchMap, tap } from 'rxjs';
import { AtlasResponseWrapper } from 'src/app/models/atlas-response-wrapper';
import { CurrentSelectionsBuilder } from 'src/app/services/current-selections.builder';
import { convertParamMapToHttpParams } from 'src/app/utils/ai-paging-sorting.utils';

@Injectable({providedIn: 'root'})
export class TurnoverRiskService {
    private readonly _url = `${environment.apiURL}/graphSeries/turnover-risk`;
    series$: ReplaySubject<ChartSeries[]> = new ReplaySubject<ChartSeries[]>();
    loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    private _series: ChartSeries[] = [
        {
          name:'',
          data: []
        }
    ];

    constructor(private http: HttpClient, private filterService: FilterService) {
        this.series$.next(this._series);
        this.subscribeToFilterServiceChanges();
    }

    private subscribeToFilterServiceChanges(): void {
        this.filterService.currentSelectionsChange$.pipe(
          tap(() => this.loading$.next(true)),
          switchMap(() => combineLatest([
              this.getTurnoverRiskGraphSeries(),
            ])
          )
        ).subscribe(([response]) => {
          this.generateSeries([response]);
          this.loading$.next(false);
        });
      }

    private getTurnoverRiskGraphSeries(): Observable<AtlasResponseWrapper<TurnoverRiskGraphSeries[]>> {
        const paramMap = CurrentSelectionsBuilder
        .newBuilder(this.filterService.currentSelections)
        .omit(['turnoverRiskStratification'])
        .buildAsParamMap();
        const params = convertParamMapToHttpParams(paramMap);
        return this.http.get<AtlasResponseWrapper<TurnoverRiskGraphSeries[]>>(
        `${this._url}`, {params});
    }

    private generateSeries([response]: AtlasResponseWrapper<TurnoverRiskGraphSeries[]>[]): void {
        const countMap = new Map<string, number>();
        const categoryOrder = ['High', 'Elevated', 'Guarded', 'Low'];
        response.result.forEach(item => {
            countMap.set(item.category, item.count);
        });

        this._series[0].data = categoryOrder.map(category => {
            return countMap.get(category) || 0;
        });
        this.series$.next(this._series);
      }
}

export class TurnoverRiskGraphSeries {
    category: string
    count: number
}

export class ChartSeries{
    name: string
    data: number[]
}
