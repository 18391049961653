<div class="display-table-row-group">
	<div class="turnover-risk-display" *ngIf="showDefaultDisplay">
		-
	</div>
	<div class="progress-bar-container" *ngIf="showProgressBar">
		<div class="progress-bar-background"></div>
		<div
			[class]="progressBarClass"
			[ngStyle]="{ width: widthPercent }"
		></div>
	</div>
</div>