<mat-form-field class="w-100 multi-select-search"
    [appearance]="fieldAppearance"
    [floatLabel]="floatLabel"
    [hideRequiredMarker]="hideRequiredMarker"
    [color]="color">

  <mat-label>
    <span *ngIf="label">{{label}}</span>
    <ng-content *ngIf="!label" select="[label]"></ng-content>
  </mat-label>

  <mat-select
    multiple
    panelClass="mat-select-panel"
    [id]="id"
    [tabIndex]="getTabIndex()"
    [formControl]="selectControl"
    [disableOptionCentering]="disableOptionCentering"
    [disableRipple]='disableRipple'
    (selectionChange)="fireSelectionChange($event)"
    (openedChange)="isPanelToggled($event)"
    [aria-label]="ariaLabel"
    [aria-labelledby]="ariaLabelledby"
    [required]="required"
  >
    <mat-form-field class="mat-form-field-search">
      <mat-label>Search Tags </mat-label>
      <input matInput type="text" [(ngModel)]="searchTerm" />
      <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <ng-container *ngFor="let element of dataSource">
      <!-- just hide them instead of using structural directive/removing from the original array so selected values persist even if there's a search active -->
      <mat-option [style.display]="shouldShow(element) ? 'flex' : 'none'"
        [value]="element.value"
        [disabled]="element.disabled"
        class="multi-select-search-option">
        {{element.label}}
      </mat-option>
    </ng-container>
  </mat-select>

  <span matPrefix>
    <ng-content select="[input-prefix]"></ng-content>
  </span>

  <span matSuffix>
    <ng-content select="[input-suffix]"></ng-content>
  </span>

  <mat-error>
    <div *ngIf="selectControl.invalid">
      <span>{{errorMessage}}</span>
      <mat-icon class="float-right">warning</mat-icon>
    </div>
  </mat-error>

</mat-form-field>
