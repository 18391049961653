import { convertToParamMap, ParamMap } from '@angular/router';
import {
  CurrentSelections,
  FilterKey,
  MultiValuedFilterKey,
  SingleValuedFilterKey
} from '../models/current-selections';
import { CurrentSelection } from '../models/current-selection';
import { CurrentSelectionDisplay } from '../models/current-selection-display';

export class CurrentSelectionsBuilder {
  private static readonly nonClearableFilters: FilterKey[] = ['startDate', 'endDate'];
  private static readonly nonChipFilters: FilterKey[] = ['caregiverSearch', 'startDate', 'endDate'];
  private static readonly filterKeyLabelMap: Map<FilterKey, string> = new Map<FilterKey, string>([
    ['caregiverSearch', 'Search'],
    ['startDate', 'Start Date'],
    ['endDate', 'End Date'],
    ['locationId', 'Location'],
    ['agencyTagId', 'Profile Tag'],
    ['turnoverRiskStratification', 'Turnover Risk']
  ]);
  private static readonly filterKeyMap: Map<FilterKey, string> = new Map<FilterKey, string>([
    ['caregiverSearch', 'Caregiver~Search'],
    ['startDate', 'ShiftDate~Start'],
    ['endDate', 'ShiftDate~End'],
    ['locationId', 'LocationId'],
    ['agencyTagId', 'AgencyTagId'],
    ['turnoverRiskStratification', 'TurnoverRiskStratification']
  ]);
  private readonly _currentSelections: CurrentSelections;
  private constructor(currentSelections: CurrentSelections) {
    this._currentSelections = Object.assign({},currentSelections);
  }

  static newBuilder(currentSelections: CurrentSelections = undefined): CurrentSelectionsBuilder {
    return new CurrentSelectionsBuilder(currentSelections);
  }

  omit(keys: FilterKey[]): CurrentSelectionsBuilder {
    const result = Object.assign({}, this._currentSelections);
    keys.forEach((key) => delete result[key])
    return CurrentSelectionsBuilder.newBuilder(result);
  }

  pick(filtersToInclude: FilterKey[]): CurrentSelectionsBuilder {
    const result = CurrentSelectionsBuilder.newBuilder().build();
    Object.keys(this._currentSelections)
      .filter(key => filtersToInclude.map(fti => fti.toString()).indexOf(key) > -1)
      .forEach(key => result[key] = this._currentSelections[key]);
    return CurrentSelectionsBuilder.newBuilder(result);
  }

  addSelection(filterKey: SingleValuedFilterKey, value: string, valueLabel: string = undefined): CurrentSelectionsBuilder {
    this._currentSelections[filterKey] = new CurrentSelection(value, valueLabel);
    return this;
  }

  addMultiValuedSelection(filterKey: MultiValuedFilterKey, values: CurrentSelection[]): CurrentSelectionsBuilder {
    this._currentSelections[filterKey] = values || [];
    return this;

  }

  clearFilter(filterKey: FilterKey): CurrentSelectionsBuilder {
    delete this._currentSelections[filterKey];
    return this;
  }

  removeFilterSelection(filterKey: FilterKey, filterSelection: CurrentSelection): CurrentSelectionsBuilder {
    if (Array.isArray(this._currentSelections[filterKey])) {
      const existingMultiSelections = this._currentSelections[filterKey] as CurrentSelection[];
      const selectionIndex = existingMultiSelections.findIndex(cs => cs.filterValue === filterSelection.filterValue);
      if (selectionIndex === -1) {
        console.warn(`Filter ${filterKey} attempted to remove value ${filterSelection.filterValue} which does not exist`);
        return;
      }
      existingMultiSelections.splice(selectionIndex, 1);

      if (existingMultiSelections.length === 0) {
        delete this._currentSelections[filterKey];
      }
    } else {
      delete this._currentSelections[filterKey];
    }
    return this;
  }

  nonClearable = (exemptList: FilterKey[] = []): CurrentSelectionsBuilder => {
    const pickList = [...exemptList, ...CurrentSelectionsBuilder.nonClearableFilters]
    return this.pick(pickList);
  }
  displayable = (): CurrentSelectionsBuilder => this.omit(CurrentSelectionsBuilder.nonChipFilters);
  build = (): CurrentSelections => Object.assign({}, this._currentSelections);
  buildAsParamMap = (): ParamMap => {
    const preparedFilters = {};
    Object.entries(this._currentSelections)
      .forEach(([key, currentSelections]) => {
        const filterKey = key as FilterKey;
        if (Array.isArray(currentSelections)) {
          preparedFilters[CurrentSelectionsBuilder.filterKeyMap.get(filterKey)] = currentSelections.map(cs => cs.filterValue).join(',')
        } else {
          preparedFilters[CurrentSelectionsBuilder.filterKeyMap.get(filterKey)] = currentSelections.filterValue;
        }
      })
    return convertToParamMap(preparedFilters);
  }

  buildAsChipDisplay = (): CurrentSelectionDisplay[] => {
    const results = [];
    Object.entries(this._currentSelections).forEach(([filterKeyString, selections]) => {
      const filterKey = filterKeyString as keyof CurrentSelections;
      if (Array.isArray(selections)) {
        for (const selection of selections) {
          results.push({
            filterKey: filterKey,
            filterKeyLabel: CurrentSelectionsBuilder.filterKeyLabelMap.get(filterKey),
            filterValue: selection.filterValue,
            filterValueLabel: selection.filterValueLabel
          });
        }
      } else {
        results.push({
          filterKey: filterKey,
          filterKeyLabel: CurrentSelectionsBuilder.filterKeyLabelMap.get(filterKey),
          filterValue: selections.filterValue,
          filterValueLabel: selections.filterValueLabel
        })
      }
    })
    return results;
  }
}
