<div class="filter-container">
  <app-filter-drawer-header></app-filter-drawer-header>
  <app-filter-clear-all-button></app-filter-clear-all-button>
  <app-filter-dates></app-filter-dates>
  <app-filter-location></app-filter-location>
  <!--<app-filter-client></app-filter-client>-->
  <app-filter-agency-tag></app-filter-agency-tag>
  <app-filter-turnover-risk></app-filter-turnover-risk>
  <!--<app-filter-referral-source></app-filter-referral-source>-->
  <!--<app-filter-tenure-range></app-filter-tenure-range>-->
</div>
