import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { CarouselItemComponent } from '../carousel-item/carousel-item.component';
import { CarouselItemPositionEnum } from '../carousel-item-position.enum';

export function carouselItemComponentMissing(): Error {
	return new Error(`
	Carousel must contain at least one CarouselItemComponent.
	Make sure to add a app-carousel-item to the app-carousel element.`);
}

@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterContentInit {
	@ContentChildren(CarouselItemComponent)
	carouselItems: QueryList<CarouselItemComponent>;
	activeIndex = 0;

	ngAfterContentInit(): void {
		if (this.carouselItems.length === 0) {
			throw carouselItemComponentMissing();
		}

		setTimeout(() => this.setActive());
	}

	nextItem() {
		if (this.activeIndex + 1 > this.carouselItems.length - 1) {
			this.activeIndex = 0;
		} else {
			this.activeIndex = this.activeIndex + 1;
		}
		this.setActive();
	}

	previousItem() {
		if (this.activeIndex === 0) {
			this.activeIndex = this.carouselItems.length - 1;
		} else {
			this.activeIndex = this.activeIndex - 1;
		}
		this.setActive();
	}

	goToIndex(index: number) {
		this.activeIndex = index;
		this.setActive();
	}

	private setActive() {
		for (let i = 0; i < this.carouselItems.length; i++) {
			const currentItem = this.carouselItems.toArray()[i];
			if (i === this.activeIndex) {
				currentItem.position = CarouselItemPositionEnum.center;
			}

			if (i < this.activeIndex) {
				currentItem.position = CarouselItemPositionEnum.left;
			}

			if (i > this.activeIndex) {
				currentItem.position = CarouselItemPositionEnum.right;
			}
		}
	}
}
