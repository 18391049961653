import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CurrentSelectionsBuilder } from './current-selections.builder';
import {
  CurrentSelections,
  FilterKey,
  MultiValuedFilterKey,
  SingleValuedFilterKey
} from '../models/current-selections';
import { CurrentSelectionDisplay } from '../models/current-selection-display';
import { CurrentSelection } from '../models/current-selection';
import { UserService } from './user.service';

@Injectable({providedIn: 'root'})
export class FilterService {
  private _currentSelectionsBuilder: CurrentSelectionsBuilder = CurrentSelectionsBuilder.newBuilder();
  private defaultStartDate: Date;
  private defaultEndDate: Date;
  private datePattern: RegExp = /(\d{4})(\d{2})(\d{2})/;
  currentSelectionsChange$: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(private userService: UserService) {
    this.userService.user$.subscribe(user => {
      this.defaultStartDate = this.calculateLast4WeeksStart(user.agencyFirstDayOfWeek, new Date());
      this.defaultEndDate = this.calculateLast4WeeksEnd(user.agencyFirstDayOfWeek, new Date());
      this.setDefaultDates();
    });
  }

  get defaultDateRangeSelected(): boolean {
    const defaultStartDate = this.parseDate(this.defaultStartDate);
    const currentStartDate = this.parseDate(this.startDate);
    const defaultEndDate = this.parseDate(this.defaultEndDate);
    const currentEndDate = this.parseDate(this.endDate);
    return defaultStartDate === currentStartDate && defaultEndDate === currentEndDate;
  }

  get currentSelections(): CurrentSelections {
    return this._currentSelectionsBuilder.build();
  }

  get currentSelectionsAsChipDisplay(): CurrentSelectionDisplay[] {
    return this._currentSelectionsBuilder
      .displayable()
      .buildAsChipDisplay();
  }

  get startDate(): Date {
    if (this.currentSelections && this.currentSelections.startDate && this.currentSelections.startDate.filterValue){
      return this.parseFilterDateString(this.currentSelections.startDate.filterValue);
    }
  }

  get endDate(): Date {
    if (this.currentSelections && this.currentSelections.endDate && this.currentSelections.endDate.filterValue) {
      return this.parseFilterDateString(this.currentSelections.endDate.filterValue);
    }
  }

  addFilter(key: SingleValuedFilterKey, value: string, valueLabel: string = undefined) {
    this._currentSelectionsBuilder.addSelection(key, value, valueLabel);
    this.currentSelectionsChange$.next();
  }

  addMultiValuedFilter(key: MultiValuedFilterKey, values: CurrentSelection[]) {
    this._currentSelectionsBuilder.addMultiValuedSelection(key, values);
    this.currentSelectionsChange$.next();
  }

  removeFilter(key: FilterKey) {
    this._currentSelectionsBuilder.clearFilter(key);
    this.currentSelectionsChange$.next();
  }

  removeFilterSelection(filterKey: FilterKey, filterSelection: CurrentSelection) {
    this._currentSelectionsBuilder.removeFilterSelection(filterKey, filterSelection);
    this.currentSelectionsChange$.next();
  }

  removeFilterSelectionByDisplay(currentSelectionDisplay: CurrentSelectionDisplay): void {
    const currentSelection = new CurrentSelection(currentSelectionDisplay.filterValue, currentSelectionDisplay.filterValueLabel);
    const filterKey = currentSelectionDisplay.filterKey;
    this._currentSelectionsBuilder.removeFilterSelection(filterKey, currentSelection);
    this.currentSelectionsChange$.next();
  }

  clearAllFilters(exemptList: FilterKey[] = []) {
    this._currentSelectionsBuilder = this._currentSelectionsBuilder.nonClearable(exemptList);

    if (exemptList.findIndex(el => el === 'startDate') === -1) {
      this._currentSelectionsBuilder = this._currentSelectionsBuilder.addSelection('startDate', this.parseDate(this.defaultStartDate));
    }

    if (exemptList.findIndex(el => el === 'endDate') === -1) {
      this._currentSelectionsBuilder = this._currentSelectionsBuilder.addSelection('endDate', this.parseDate(this.defaultEndDate));
    }

    this.currentSelectionsChange$.next();
  }

  clearFilterBarFilters(): void {
    this.clearAllFilters(['caregiverSearch']);
  }

  /*
  * ClearCare Day of Week Mappings
  * 0: Monday
  * 1: Tuesday
  * 2: Wednesday
  * 3: Thursday
  * 4: Friday
  * 5: Saturday
  * 6: Sunday
  */
  calculateLast4WeeksStart(firstDayOfWeek: number, today: Date): Date {
    const jsFirstDayOfWeek = firstDayOfWeek === 6 ? 0 : firstDayOfWeek + 1;
    const fourWeekEnd = this.calculateLast4WeeksEnd(firstDayOfWeek, today);
    let lastStartOfWeek = new Date(fourWeekEnd.setDate(fourWeekEnd.getDate() - 1));
    while (lastStartOfWeek.getDay() !== jsFirstDayOfWeek) {
      lastStartOfWeek = new Date(lastStartOfWeek.setDate(lastStartOfWeek.getDate() - 1));
    }
    return new Date(lastStartOfWeek.setDate(lastStartOfWeek.getDate() - 21));
  }

  calculateLast4WeeksEnd(firstDayOfWeek: number, today: Date): Date {
    const jsFirstDayOfWeek = firstDayOfWeek === 6 ? 0 : firstDayOfWeek + 1;
    const jsLastDayOfWeek = (jsFirstDayOfWeek + 6) % 7;
    let lastEndOfWeek = new Date(today.setDate(today.getDate() - 1));
    while (lastEndOfWeek.getDay() !== jsLastDayOfWeek) {
      lastEndOfWeek = new Date(lastEndOfWeek.setDate(lastEndOfWeek.getDate() - 1));
    }
    return lastEndOfWeek;
  }

  parseDate(date: Date): string {
    if (!date) return null;
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
    const day = localDate.getDate().toString().padStart(2, '0');
    return `${year}${month}${day}`;
  }

  parseFilterDateString(filterDateString: string): Date {
    return new Date(filterDateString.replace(this.datePattern, '$1-$2-$3T00:00:00'))
  }

  setDefaultDates() {
    this._currentSelectionsBuilder
      .addSelection('startDate', this.parseDate(this.defaultStartDate))
      .addSelection('endDate', this.parseDate(this.defaultEndDate));
    this.currentSelectionsChange$.next();
  }
}
