import { Component, OnInit } from '@angular/core';
import { FilterService } from '../../../services/filter.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-filter-date-selection-display',
  templateUrl: './filter-date-selection-display.component.html',
  styleUrls: ['./filter-date-selection-display.component.scss']
})
export class FilterDateSelectionDisplayComponent implements OnInit {
  startDate: Date;
  endDate: Date;

  constructor(private filterService: FilterService) {
  }

  ngOnInit(): void {
    this.filterService.currentSelectionsChange$.pipe(
      filter(() => {
        return this.filterService.currentSelections.startDate !== undefined && this.filterService.currentSelections.endDate !== undefined;
      })
    ).subscribe(() => {
      this.startDate = this.filterService.startDate;
      this.endDate = this.filterService.endDate;
    });
  }
}
