import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, map, shareReplay } from "rxjs";
import { ProfileLocation } from "../models/profile-location";
import { UserLocation } from "../models/user";
import { WebApiResponse } from "../models/response";


@Injectable({providedIn: 'root'})
export class LocationService{
    private getLocations = `getLocations`
    private getActiveLocations = `getActiveLocations`
    private locationUrl = `${environment.apiURL}/location`;
    private profileLocationUrl = `${environment.apiURL}/profilelocation`;
    
    constructor(private http: HttpClient){}

    public getLocationList = (): Observable<UserLocation[]> => {
        return this.http.get<WebApiResponse<UserLocation[]>>(
            `${this.locationUrl}/${this.getLocations}`
          ).pipe(
            map(response => response.result),
            shareReplay(1)
        );
    }

    public getProfileLocationList = (): Observable<ProfileLocation[]> => {
        return this.http.get<WebApiResponse<ProfileLocation[]>>(
          `${this.profileLocationUrl}/${this.getLocations}`)
          .pipe(
            map(response => response.result),
            shareReplay(1)
        );
    }

    public getActiveProfileLocationList = (): Observable<ProfileLocation[]> => {
        return this.http.get<WebApiResponse<ProfileLocation[]>>(
          `${this.profileLocationUrl}/${this.getActiveLocations}`).pipe(
            map(response => response.result),
            shareReplay(1)
        );
    }
}