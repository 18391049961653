import { NgModule } from '@angular/core';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	imports: [CommonModule, MatButtonModule, MatIconModule],
	exports: [CarouselComponent, CarouselItemComponent],
	declarations: [CarouselComponent, CarouselItemComponent]
})
export class CarouselModule {}
