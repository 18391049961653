  <mat-sidenav-container class="sidenav-container-override" (backdropClick)="filterDrawerService.toggle()">
    <mat-sidenav mode="over" [autoFocus]="false">
      <app-filter-drawer></app-filter-drawer>
    </mat-sidenav>
    <mat-sidenav-content class="caregiver-management-dashboard-background">
      <app-caregiver-management-dashboard-title></app-caregiver-management-dashboard-title>
      <app-filter-date-selection-display></app-filter-date-selection-display>
      <app-filter-toggle-current-selections-container></app-filter-toggle-current-selections-container>
      <app-caregiver-management-dashboard-chart-container></app-caregiver-management-dashboard-chart-container>
      <app-caregiver-performance-grid-container></app-caregiver-performance-grid-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
