import { Component, Input, OnChanges } from '@angular/core'
import { FilterService } from '../../../services/filter.service';

@Component({
    selector: 'app-avgdailymileage-display',
    templateUrl: './avgdailymileage-display.component.html',
    styleUrls: ['./avgdailymileage-display.component.scss']
})
export class AvgDailyMileageDisplayComponent implements OnChanges{
    @Input() avgDailyMileage: number;
    @Input() avgDailyMileageArrow: string;
    showAvgDailyMileage: boolean = true;
    showAvgDailyMileageArrow: boolean = true;

    constructor(public filterService: FilterService){}
    ngOnChanges(): void {
        if (!this.avgDailyMileage)
            this.showAvgDailyMileage = false;
    }
}
