import { Component, OnInit } from '@angular/core';
import { UserPreferenceService } from '../../../services/user-preference.service';
import { ColumnPreference, UserPreference } from '../../../models/user-preference';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-manage-columns-dropdown',
  templateUrl: './manage-columns-dropdown.component.html',
  styleUrls: ['./manage-columns-dropdown.component.scss']
})
export class ManageColumnsDropdownComponent implements OnInit {

  userPreference: UserPreference;

  constructor(private userPreferenceService: UserPreferenceService) {
  }

  ngOnInit(): void {
    this.userPreferenceService.userPreferences$
      .subscribe(userPreferences =>
        this.userPreference = userPreferences);
  }

  savePreferences() {
    this.userPreferenceService.saveUserPreferences(this.userPreference);
  }

  checkAndUpdate(column: ColumnPreference) {
    column.hideColumn = !column.hideColumn;
    this.savePreferences();
  }

  drop(event: CdkDragDrop<ColumnPreference[]>) {
    moveItemInArray(this.userPreference?.caregiverDashboardColumns, event.previousIndex, event.currentIndex);
    this.savePreferences();
  }
}
