<div *ngIf="showAvgDailyMileage; else noAvgDailyMileageInput" class="d-flex align-items-center right-align">
    <div class="avg-daily-mileage-number">
      <ng-container>{{avgDailyMileage | number:'1.0-2'}} </ng-container>
    </div>
    <div *ngIf="showAvgDailyMileageArrow" class="avg-daily-mileage-arrow">
      <ng-container *ngIf="filterService?.defaultDateRangeSelected">
        <img src="../../../../assets/icons/trending_flat.svg" class="trending-arrow-{{ avgDailyMileageArrow }}">
      </ng-container>
    </div>
</div>
<ng-template #noAvgDailyMileageInput>
  <div class="d-flex  align-items-center right-align">
    <div class="avg-daily-mileage-number">
      -
    </div>
  </div>
</ng-template>
