<form [formGroup]="dateFormGroup" class="date-picker-form">
  <div class="picker-containers">
    <mat-form-field appearance="fill" class="date-picker" data-cy="filter-start-date">
      <mat-label>From</mat-label>
      <input matInput [matDatepicker]="startPicker" formControlName="startDate" [min]="minDate" [max]="maxDate">
      <mat-datepicker-toggle matSuffix [for]="startPicker" data-cy="datepicker-toggle"></mat-datepicker-toggle>
      <mat-datepicker #startPicker startView="month"></mat-datepicker>
      <mat-error *ngIf="dateFormGroup.controls.startDate.hasError('matDatepickerMin')">From date must be
        after {{minDate.toDateString()}}</mat-error>
      <mat-error *ngIf="dateFormGroup.controls.startDate.hasError('matDatepickerMax')">From date must be
        before {{maxDate.toDateString()}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="date-picker" data-cy="filter-end-date">
      <mat-label>To</mat-label>
      <input matInput [matDatepicker]="endPicker" formControlName="endDate" [min]="minDate" [max]="maxDate"
             [errorStateMatcher]="matcher">
      <mat-datepicker-toggle matSuffix [for]="endPicker" data-cy="datepicker-toggle"></mat-datepicker-toggle>
      <mat-datepicker #endPicker startView="month"></mat-datepicker>
      <mat-error *ngIf="dateFormGroup.controls.endDate.hasError('matDatepickerMin')">To date must be
        after {{minDate.toDateString()}}</mat-error>
      <mat-error *ngIf="dateFormGroup.controls.endDate.hasError('matDatepickerMax')">To date must be
        before {{maxDate.toDateString()}}</mat-error>
      <mat-error
        *ngIf="dateFormGroup.hasError('startGreaterThanEnd') && !dateFormGroup.controls.endDate.hasError('matDatepickerMin') && !dateFormGroup.controls.endDate.hasError('matDatepickerMax')">
        To date must be after From date
      </mat-error>
    </mat-form-field>
  </div>
</form>
