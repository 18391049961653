import { Component } from '@angular/core';
import { PendoService } from './services/pendo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private pendoService: PendoService)
    {
      this.pendoService.initialize();
    }
}
