export interface ChangeIndicator {
  direction: Direction;
  value: number | undefined;
}

export enum Direction {
  up = 'Up',
  steady = 'Steady',
  down = 'Down',
  none = 'None'
}
