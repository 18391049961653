import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class RedirectService {
  redirect(window: Window, newLocation: string) {
    window.location.href = newLocation;
  }

  openNewWindow(window: Window, newLocation: string) {
    window.open(newLocation, '_blank');
  }
}
