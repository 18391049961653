import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { first, Observable, ReplaySubject } from 'rxjs';
import { UserPreference } from '../models/user-preference';
import { AtlasResponseWrapper } from '../models/atlas-response-wrapper';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserPreferenceService {

  userPreferences$: ReplaySubject<UserPreference> = new ReplaySubject<UserPreference>();

  constructor(private httpClient: HttpClient) {
    this.getUserPreferences().subscribe(userPreference => this.userPreferences$.next(userPreference));
  }

  private getUserPreferences(): Observable<UserPreference> {
    return this.httpClient.get<AtlasResponseWrapper<UserPreference>>(`${environment.apiURL}/user/preferences`)
      .pipe(map(atlasResponse => atlasResponse.result));
  }

  public saveUserPreferences(userPreference: UserPreference): void {
    this.httpClient.put<AtlasResponseWrapper<UserPreference>>(`${environment.apiURL}/user/preferences`, userPreference)
      .pipe(first(), map(atlasResponse => atlasResponse.result))
      .subscribe(userPreference => this.userPreferences$.next(userPreference));
  }
}
