<div class="empty-state" *ngIf="!hasColumnsSelected; else noResultsFiltered" data-cy="grid-empty-state">
  <img src="assets/no-results-found.svg" width="220px" alt="empty-results">
  <h3 class="empty-state-title">No Columns Selected</h3>
  <p class="empty-state-subtitle">Please select at least one column from the Manage Columns menus</p>
</div>

<ng-template #noResultsFiltered>
  <div class="empty-state" *ngIf="isActiveSearch || isActiveFilter; else defaultEmpty" data-cy="grid-empty-state">
    <img src="assets/no-results-found.svg" width="220px" alt="empty-results">
    <h3 class="empty-state-title">No Caregivers Found!</h3>
    <p class="empty-state-subtitle">Please check the search term or modify filter criteria.</p>
    <button mat-stroked-button color="accent" data-cy="empty-state-clear-filters-button"
            (click)="clearFiltersAndSearch()">
      CLEAR FILTERS
    </button>
  </div>
</ng-template>

<ng-template #defaultEmpty>
  <div class="empty-state" data-cy="grid-empty-state">
    <img src="assets/no-results-found.svg" width="220px" alt="empty-results">
    <h3 class="empty-state-title">No Caregivers Found!</h3>
  </div>
</ng-template>
