import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AgencyTag } from "../models/agency-tag";
import { Observable, map, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";
import { WebApiResponse } from "../models/response";

@Injectable({providedIn: 'root'})
export class AgencyTagService{
    private getAgencyTags = `getAgencyTags`;
    private agencyTagUrl = `${environment.apiURL}/agencytag`

    constructor(private httpClient: HttpClient){}

    public getAgencyTagList = (): Observable<AgencyTag[]> => {
        return this.httpClient.get<WebApiResponse<AgencyTag[]>>(
            `${this.agencyTagUrl}/${this.getAgencyTags}`
        ).pipe(
            map(response => response.result),
            shareReplay(1)
        );
    }
}