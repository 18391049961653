export class CurrentSelection {
  private readonly _filterValue: string;
  private readonly _filterValueLabel: string;

  constructor(filterValue: string, filterValueLabel: string = undefined) {
    this._filterValue = filterValue;
    this._filterValueLabel = filterValueLabel || filterValue;
  }

  get filterValue(): string {
    return this._filterValue;
  }

  get filterValueLabel(): string {
    return this._filterValueLabel;
  }
}
