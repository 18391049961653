import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-turnover-risk',
  templateUrl: './turnover-risk.component.html',
  styleUrls: ['./turnover-risk.component.scss']
})
export class TurnoverRiskComponent implements OnChanges {
  @Input() turnoverRiskScore: number;
  @Input() turnoverRiskStratification: number;

  showDefaultDisplay: boolean = true;
  readonly turnoverRiskDefaultDisplay: string = '-';
  showProgressBar: boolean = true;
  widthPercent = '0%';
  progressBarClass: string = 'progress-bar';

  ngOnChanges(): void {
    this.setShowDefaultDisplay();
  }

  private setShowDefaultDisplay() {
    if (this.turnoverRiskScore === undefined || this.turnoverRiskScore === null) {
      this.showDefaultDisplay = true;
      this.showProgressBar = false;
    } else {
      this.showDefaultDisplay = false;
      this.widthPercent = this.turnoverRiskScore * 100 + "%"
      this.progressBarClass = 'progress-bar-' + this.turnoverRiskStratification;
    }
  }

}
