import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-tenure-display',
  templateUrl: './tenure-display.component.html',
  styleUrls: ['./tenure-display.component.scss']
})
export class TenureDisplayComponent implements OnChanges {

  @Input() tenureMonths: number | null | undefined;
  yearCount: number | null;
  monthCount: number | null;
  showYear: boolean = false;
  showMonth: boolean = false;
  showSpace: boolean = false;

  ngOnChanges(): void {
    if (this.tenureMonths === null || this.tenureMonths === undefined) return;
    this.yearCount = Math.floor(this.tenureMonths / 12);
    this.monthCount = this.calculateMonthCount(this.tenureMonths);
    this.showYear = this.shouldShowYear(this.yearCount, this.monthCount);
    this.showMonth = this.shouldShowMonth(this.yearCount, this.monthCount);
    this.showSpace = this.shouldShowSpace(this.showYear, this.showMonth);
  }

  private calculateMonthCount(tenureMonths: number | null | undefined): number | null {
    if (tenureMonths === null || tenureMonths === undefined) return null;
    return Math.floor(this.tenureMonths % 12);
  }

  private shouldShowYear(yearCount: number, monthCount: number): boolean {
    return yearCount > 0;
  }

  private shouldShowMonth(yearCount: number, monthCount: number) {
    return yearCount === 0 && monthCount === 0 || monthCount > 0;
  }

  private shouldShowSpace(showYear: boolean, showMonth: boolean) {
    return showYear && showMonth;
  }
}
