import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FilterService } from '../../../services/filter.service';

@Component({
  selector: 'app-caregiver-performance-grid-search',
  templateUrl: './caregiver-performance-grid-search.component.html',
  styleUrls: ['./caregiver-performance-grid-search.component.scss']
})
export class CaregiverPerformanceGridSearchComponent implements OnInit {
  caregiverSearchFormGroup: FormGroup;

  constructor(private filterService: FilterService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initializeForm();
    this.subscribeToFormChanges();
    this.subscribeToFilterSelectionChanges();
  }

  resetForm(): void {
    this.caregiverSearchFormGroup.patchValue({
      caregiverNameSearch: ''
    });
  }

  get searchValue(): string {
    return this.caregiverSearchFormGroup.value.caregiverNameSearch.trim();
  }

  private initializeForm(): void {
    this.caregiverSearchFormGroup = this.formBuilder.group({
      caregiverNameSearch: ['']
    });
  }

  private subscribeToFormChanges(): void {
    this.caregiverSearchFormGroup.valueChanges.pipe(debounceTime(300)).subscribe(valueChanged => {
      if (this.searchValue === '') {
        this.filterService.removeFilter('caregiverSearch');
        return;
      }
      this.filterService.addFilter('caregiverSearch', this.searchValue);
    });
  }

  private subscribeToFilterSelectionChanges(): void {
    this.filterService.currentSelectionsChange$.subscribe(() => {
      if (this.filterService.currentSelections.caregiverSearch === undefined && this.searchValue !== '') {
        this.resetForm();
      }
    });
  }
}
