import { Component } from '@angular/core';
import { carouselAnimations } from '../carousel-animations';
import { CarouselItemPositionEnum } from '../carousel-item-position.enum';

@Component({
	selector: 'app-carousel-item',
	templateUrl: './carousel-item.component.html',
	styleUrls: ['./carousel-item.component.scss'],
	animations: [carouselAnimations.translateCarouselItem]
})
export class CarouselItemComponent {
	animationDuration = '600ms';
	position: CarouselItemPositionEnum = CarouselItemPositionEnum.right;
}
