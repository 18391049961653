<div class="caregiver-performance-grid-options-header">
  <h3 class="caregiver-performance-grid-title">Caregiver Performance</h3>
  <div class="caregiver-performance-grid-options">

    <div class="caregiver-performance-grid-buttons">
      <app-manage-columns-dropdown class="manage-columns-button"></app-manage-columns-dropdown>
      <button
      mat-stroked-button
      color="accent"
      data-cy="export-to-csv-button"
      class="export-to-csv-button"
      (click)="exportCSV()"
      [disabled]="!allowExportToCsv">
        <mat-icon inline="true" class="material-icons-outlined icon-button">note</mat-icon>
        <span *ngIf="hasCSVDownloaded">EXPORT TO CSV</span>
        <span *ngIf="!hasCSVDownloaded">Downloading</span>
      </button>
    </div>
  </div>
</div>
