<app-multi-select-search
  [formControl]="agencyTagFilterFormControl"
  [default]="defaultOption"
  [dataSource]="agencyTagData"
  [multiple]="true"
  [fieldAppearance]="'fill'"
  [label]="'Profile Tag'"
  (selectionChange)="updateAgencyTagFilter()"
  data-cy="agency-tag-filter"
>
</app-multi-select-search>
