import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({providedIn: 'root'})
export class FilterDrawerService {
  private filterDrawerComponent: MatSidenav | undefined;
  toggleChange$: Subject<void> = new Subject<void>();
  toggle() {
    this.toggleChange$.next();
  }

  register(filterDrawerComponent: MatSidenav) {
    if (this.filterDrawerComponent) {
      throw new Error('Filter Drawer Component is already registered');
    }
    this.filterDrawerComponent = filterDrawerComponent;
  }

  get opened() {
    return this.filterDrawerComponent?.opened ?? false;
  }
}
