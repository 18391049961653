<ng-container *ngIf="(turnoverService.series$ | async) as series">
  <div id="turnover-chart" data-cy="turnover-chart" *ngIf="series.length > 0 && series[0].data.length > 0">
    <apx-chart
      [series]="series"
      [chart]="settings.chart"
      [xaxis]="settings.xAxis"
      [stroke]="settings.stroke"
      [tooltip]="settings.toolTip"
      [dataLabels]="settings.dataLabels"
      [legend]="settings.legend"
      [markers]="settings.markers"
      [grid]="settings.grid"
      [yaxis]="settings.yAxis"
      [colors]="settings.colors"
    ></apx-chart>
  </div>
  <mat-spinner *ngIf="turnoverService.loading$ | async"></mat-spinner>
</ng-container>
