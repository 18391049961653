import { Component,  Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { first } from 'rxjs';
import { ApiSortDirection } from 'src/app/models/pagination';
import { CaregiverManagementDashboardService } from 'src/app/services/caregiver-management-dashboard.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-caregiver-performance-grid-options-header',
  templateUrl: './caregiver-performance-grid-options-header.component.html',
  styleUrls: ['./caregiver-performance-grid-options-header.component.scss']
})
export class CaregiverPerformanceGridOptionsHeaderComponent {
  constructor(
    private caregiverManagementDashboardService: CaregiverManagementDashboardService,
    private filterService: FilterService){
  }
  @Input() allowExportToCsv: boolean;
  @Input() sort: MatSort;
  hasCSVDownloaded?: boolean = true;

  exportCSV() {
      const sortDirection = this.sort?.direction || ApiSortDirection.Ascending;
      this.hasCSVDownloaded = false;
      const pagination = {
          page: 0,
          pageSize: 10000,
          sortBy: this.sort?.active,
          sortDirection:  sortDirection as ApiSortDirection,
        };
        this.caregiverManagementDashboardService
          .getListCsv(this.filterService.currentSelections, pagination)
          .pipe(first())
      .subscribe((resp) => {
          this.hasCSVDownloaded = true;
      });
    }
}
