import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FilterService} from './filter.service';
import {LineSeriesMetric, SeriesName} from '../models/graph-series';
import {LineChartServiceBase} from './line-chart.service.base';

@Injectable({providedIn: 'root'})
export class TurnoverService extends LineChartServiceBase {

  constructor(http: HttpClient, filterService: FilterService) {
    super(http, filterService, [
      SeriesName.agency
    ],
    LineSeriesMetric.turnover);
  }
}
