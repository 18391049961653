import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { TeamEngageDashboardComponent } from './team-engage-dashboard.component';
import { TeamEngageDashboardRoutingModule } from './team-engage-dashboard-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
      TeamEngageDashboardComponent,
    ],
    imports: [
      CommonModule,
      CoreModule,
      TeamEngageDashboardRoutingModule,
      MatProgressSpinnerModule
    ],
    providers: [],
    exports: [],
  })
  export class TeamEngageDashboardModule {
  }