import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
declare var AvaSDK: any; 

@Injectable({providedIn: 'root'})
export class TeamEngageService{
  private url = `${environment.apiURL}/teamEngage`;
  private sdkInstance: any
  private isInitialized: boolean = false;
  private sdkInitialized: Subject<void> = new Subject<void>();
  public sdkInitialized$: Observable<void> = this.sdkInitialized.asObservable();

  constructor(private http: HttpClient) {
    this.loadSdkScript().then(()=>{
      this.initializeSdk();
    }).catch(error => {
      console.error('Failed to initialize SDK: ', error);
    });
  }

  private loadSdkScript(): Promise<void>{
    return new Promise<void>((resolve, reject) =>{
      const script = document.createElement('script');
      script.src = environment.avaSDKUrl;
      script.onload = () => resolve();
      script.onerror = () => {
        console.error('Failed to load SDK script');
        reject();
      };
      document.body.appendChild(script);
    })
  }

  private initializeSdk(): void{
    this.sdkInstance = new AvaSDK({
      sdkToken: 'clearcare',
      jwtRefreshRateSeconds: 200,
      getJwt: () => this.getJwtToken(),
      iframeClassNames: ['clearcare-ava-embed'],
      watchDomChanges: false,
      onLoaded: () => {
        this.isInitialized = true;
        // propagate event that SDK has been fully initialized
        this.sdkInitialized.next();
      }
    });
  }

  private getJwtToken(): Promise<any> {
    return this.http.get<any>(`${this.url}/generateToken`).toPromise()
      .then(response => {
        if(response && response.token){
          return response.token;
        } else {
          throw new Error('Token not found in API response');
        }
      })
      .catch(error => {
        console.error('Error fetching JWT token from API: ', error);
        throw error;
      });
  }

  public attachIframe(elementIdentifier: any): void{
    if(this.sdkInstance){
      this.sdkInstance.attachToElement(elementIdentifier);
    }
    else{
      console.error('Unable to attach iframe, SDK is undefined');
    }
  }

  public detachIframe(elementIdentifier: any): void{
    if(this.sdkInstance){
      this.sdkInstance.detachFromElement(elementIdentifier);
    }
    else{
      console.error('Unable to detach iframe, SDK is undefined');
    }
  }

  public isSdkInitialized(): boolean{
    return this.isInitialized;
  }
}