import { Injectable } from '@angular/core';
import {
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from 'ng-apexcharts';

@Injectable()
export class LineChartSettingsService {

  public configureYAxisTitle(title: string): LineChartSettingsService {
    this.yAxis.title.text = title;
    return this;
  }

  public configureYAxisTickFormat(formatterFn: (val: number, opts?: any) => string): LineChartSettingsService {
    this.yAxis.labels.formatter = formatterFn;
    return this;
  }

  public configureTooltip(formatterFn: (val: number, opts?: any) => string): LineChartSettingsService {
    this.toolTip.y['formatter'] = formatterFn;
    return this;
  }

  public configureYAxisMin(minimum: number | ((min: number) => number)): LineChartSettingsService {
    this.yAxis.min = minimum;
    return this;
  }

  public configureYAxisMax(maximum: number | ((max: number) => number)): LineChartSettingsService {
    this.yAxis.max = maximum;
    return this;
  }

  public configureYAxisTickAmount(tickAmount: number): LineChartSettingsService {
    this.yAxis.tickAmount = tickAmount;
    return this;
  }

  public configureStrokeDashArray(stroke: number[]): LineChartSettingsService {
    this.stroke.dashArray = stroke;
    return this;
  }

  public configureMarker(size: number[], color: string[], strokeColor: string[]): LineChartSettingsService {
    this.markers.size = size;
    this.markers.colors = color;
    this.markers.strokeColors = strokeColor;
    return this;
  }

  public chart: ApexChart = {
    height: 220,
    type: 'line',
    toolbar: {
      show: false
    }
  };
  public colors: string[] = ['#97449C', '#7AD1FB', '#2A83C8']
  public xAxis: ApexXAxis = {
    type: 'category',
    tickPlacement: 'between',
    labels: {
      trim: false,
      rotate: -33,
      rotateAlways: false,
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  };
  public stroke: ApexStroke = {
    width: 2,
    curve: 'straight',
    dashArray: [0, 4, 4]
  };
  public toolTip: ApexTooltip = {
    y: {
        formatter: undefined,
        title: {
          formatter(seriesName: string): string {
            return seriesName;
          }
        }
      }
  };
  public dataLabels: ApexDataLabels = {
    enabled: false
  };
  public legend: ApexLegend = {
    position: 'top',
    showForSingleSeries: true,
    showForNullSeries: false,
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    },
    onItemHover: {
      highlightDataSeries: false
    },
    markers: {
      radius: 0,
      offsetY: 2,
      onClick: undefined
    }
  };
  public markers: ApexMarkers = {
    size: [4, 0, 0],
    colors: ['#fff', undefined, undefined],
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeColors: ['#97449C', '#2A83C8', '#7AD1FB'],
    hover: {
      sizeOffset: 2
    }
  };
  public yAxis: ApexYAxis = {
    show: true,
    decimalsInFloat: 0,
    tickAmount: undefined,
    min: undefined,
    max: undefined,
    forceNiceScale: false,
    title: {
      text: '',
      rotate: -90,
      style: {
        color: '#3D5463',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Roboto'
      }
    },
    labels: {
      minWidth: 48,
      maxWidth: 48,
      formatter: undefined,
    }
  };
  public grid: ApexGrid = {
    borderColor: '#f1f1f1',
    position: 'back'
  };
}
