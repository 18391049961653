import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'CaregiverManagementDashboard', pathMatch: 'full' },
  {
    // data: { allowedRoles: [UserRole.User] },
    path: 'CaregiverManagementDashboard',
    loadChildren: () =>
      import('./caregiver-management-dashboard/caregiver-management-dashboard.module').then((m) => m.CaregiverManagementDashboardModule),
  },
  {
    path: 'TeamEngage',
    loadChildren: () =>
      import('./team-engage-dashboard/team-engage-dashboard.module').then((m) => m.TeamEngageDashboardModule),
  },
  // Catch-all, in case of invalid path.
  { path: '**', redirectTo: '/CaregiverManagementDashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
