import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, first, map } from 'rxjs';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';

@Component({
  selector: 'app-caregiver-management-dashboard-chart-container',
  templateUrl: './caregiver-management-dashboard-chart-container.component.html',
  styleUrls: ['./caregiver-management-dashboard-chart-container.component.scss']
})
export class CaregiverManagementDashboardChartContainerComponent implements OnInit {

  enableAva$: Observable<boolean>;

  constructor(private featureFlagService: FeatureFlagService) {
  }
  
  ngOnInit() {
    const teamEngageFlag$ = this.featureFlagService.get("tipc_enable_ava").pipe(map(flagVarient => flagVarient === 'on'))

    this.enableAva$ = combineLatest([teamEngageFlag$]).pipe(
      map(([teamEngageFlag]) => teamEngageFlag)
    )
  }
}
