import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { WebApiResponse } from '../models/response';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$: Observable<User>;

  // Token gets placed within a router outlet. The navbar is outside the router outlet so any
  // requests need to ensure token exists first.
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.user$ = this.authService.isAuthenticated$.pipe(
      filter(isAuthenticated => isAuthenticated),
      switchMap(hasAuthToken => this.httpClient.get<WebApiResponse<User>>(`${environment.apiURL}/user`)),
      map((response) => response.result),
      shareReplay(1)
    );
  }
}
