import { NgModule } from '@angular/core';
import { LastPipe } from './pipes/last.pipe';
import { FeatureFlagDirective } from './feature-flag.directive';

@NgModule({
  declarations: [
    LastPipe,
    FeatureFlagDirective
  ],
  imports: [],
  exports: [LastPipe, FeatureFlagDirective],
})
export class CoreModule {
}
