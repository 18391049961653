import { Component, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-caregiver-performance-grid-container',
  templateUrl: './caregiver-performance-grid-container.component.html',
  styleUrls: ['./caregiver-performance-grid-container.component.scss']
})
export class CaregiverPerformanceGridContainerComponent implements OnInit {

  constructor() { }

  allCaregiverCount: number;
  allCaregiverTabLabel: string;
  allowExportToCsv: boolean;
  sort: MatSort;
 
  ngOnInit(): void {
    // TODO: set this for real
    this.allCaregiverCount = 50;
    this.allCaregiverTabLabel = `All Caregivers (${this.allCaregiverCount})`;
  }
}
