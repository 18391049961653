import { AfterContentChecked, Component, OnInit, Renderer2 } from '@angular/core';
import { LineChartSettingsService } from '../line-chart.settings.service';
import { ShiftsCancelledService } from '../../../services/shifts-cancelled.service';

@Component({
  selector: 'app-shifts-cancelled-chart',
  templateUrl: './shifts-cancelled-chart.component.html',
  styleUrls: ['./shifts-cancelled-chart.component.scss']
})
export class ShiftsCancelledChartComponent implements OnInit, AfterContentChecked {
  constructor(public shiftsCancelledService: ShiftsCancelledService, public settings: LineChartSettingsService,
              private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this.settings = Object.assign({}, this.settings
      .configureYAxisTitle('Shifts Canceled Rate (%)')
      .configureYAxisTickFormat((val: number) => `${Math.floor(val * 100.0)}%`)
      .configureYAxisMin(0)
      .configureYAxisMax(1)
      .configureYAxisTickAmount(5)
      .configureTooltip((val: number, opts?: any) => {
        if (val === null || val === undefined) return 'No Data';
        return `${Math.round(val * 100.00 * 10.0) / 10}%`
      }));

    this.shiftsCancelledService.resize$.subscribe(() => this.ngAfterContentChecked());
  }

  ngAfterContentChecked() {
    // Hack to move line to be in front of grid border
    // SVG doesn't have a concept of Z-Index and the
    // Line Series is being rendered prior to the grid border
    // Remove when this issue is resolved:
    // https://github.com/apexcharts/apexcharts.js/issues/3599
    const shiftCancelledChart = document.querySelector('div[data-cy=shifts-cancelled-chart]');
    const childSeries = shiftCancelledChart?.querySelector('.apexcharts-line-series');
    if (childSeries) {
      this.renderer.appendChild(childSeries.parentElement, childSeries);
    }
  }
}
