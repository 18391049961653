import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import * as utc from 'dayjs/plugin/utc';
import * as minmax from 'dayjs/plugin/minMax';
import * as duration from 'dayjs/plugin/duration';

// Add plugins to DayJS
dayjs.extend(isBetween);
dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(minmax);
dayjs.extend(duration);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
