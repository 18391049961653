import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { User } from '../models/user';
import { FilterDrawerService } from '../services/filter-drawer.service';

@UntilDestroy()
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  userIsLoggedIn = false;
  hasAdminRole = false;
  hasUserRole = false;
  user: User;
  userFullName: string;

  constructor(
    private router: Router,
    private userService: UserService,
    public filterDrawerService:  FilterDrawerService
  ) {}

  ngOnInit(): void {
    this.userService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.userFullName = this.user.name;
    });
  }
}
