import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { FilterService } from '../../../services/filter.service';
import { FilterDrawerService } from '../../../services/filter-drawer.service';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-filter-dates',
  templateUrl: './filter-dates.component.html',
  styleUrls: ['./filter-dates.component.scss']
})
export class FilterDatesComponent implements OnInit {
  dateFormGroup: FormGroup;
  minDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  maxDate: Date = new Date(new Date().setDate(new Date().getDate() - 1));
  matcher = new GroupAndControlMatcher();

  constructor(
    private fb: FormBuilder,
    private filterService: FilterService,
    private filterDrawerService: FilterDrawerService) {
  }

  ngOnInit(): void {
    this.initializeFormGroup();
    this.subscribeToFormGroupChanges();
    this.subscribeToFilterSelectionChanges();
    this.subscribeToFilterDrawerChanges();
  }

  private initializeFormGroup(): void {
    this.dateFormGroup = this.fb.group({
      startDate: new FormControl(this.filterService.startDate),
      endDate: new FormControl(this.filterService.endDate)
    }, {validators: startDateGreaterThanEndDateValidator});
  }

  private subscribeToFormGroupChanges(): void {
    this.dateFormGroup.valueChanges.subscribe(
      valueChanges => {
        const shouldUpdate = this.shouldUpdateFilterService();
        if (!shouldUpdate) {
          return;
        }

        this.updateStartDateFilter(valueChanges);
        this.updateEndDateFilter(valueChanges);
      })
  }

  private updateStartDateFilter(valueChanges: any): void {
    const startValueForm = this.filterService.parseDate(valueChanges.startDate);
    const startValueService = this.filterService.parseDate(this.filterService.startDate);

    if (startValueForm !== startValueService) {
      this.filterService.addFilter('startDate', startValueForm);
      this.dateFormGroup.markAsPristine();
    }
  }

  private updateEndDateFilter(valueChanges: any): void {
    const endValueForm = this.filterService.parseDate(valueChanges.endDate);
    const endValueService = this.filterService.parseDate(this.filterService.endDate);

    if (endValueForm !== endValueService) {
      this.filterService.addFilter('endDate', endValueForm);
      this.dateFormGroup.markAsPristine();
    }
  }

  private subscribeToFilterDrawerChanges(): void {
    this.filterDrawerService.toggleChange$.subscribe(() => {
      this.dateFormGroup.markAsPristine();
      if (this.dateFormGroup.invalid || this.dateFormGroup.controls.startDate.invalid || this.dateFormGroup.controls.endDate.invalid) {
        this.filterService.setDefaultDates();
      }
      // If requirement is ever to go back to the last successful filter
      // use the next line instead
      // this.syncWithFilterService();
    });
  }

  private subscribeToFilterSelectionChanges(): void {
    this.filterService.currentSelectionsChange$.subscribe(() => this.syncWithFilterService());
  }

  private syncWithFilterService(): void {
    const startValueForm = this.filterService.parseDate(this.dateFormGroup.get('startDate').value);
    const startValueService = this.filterService.parseDate(this.filterService.startDate);

    if (startValueForm !== startValueService) {
      this.dateFormGroup.get('startDate').patchValue(this.filterService.startDate);
      this.dateFormGroup.markAsPristine();
    }

    const endValueForm = this.filterService.parseDate(this.dateFormGroup.get('endDate').value);
    const endValueService = this.filterService.parseDate(this.filterService.endDate);

    if (endValueForm !== endValueService) {
      this.dateFormGroup.get('endDate').patchValue(this.filterService.endDate);
      this.dateFormGroup.markAsPristine();
    }
  }

  private shouldUpdateFilterService(): boolean {
    // don't set filter if any of these criteria are true
    // - cross form validation (start > end) not valid
    // - data was changed not via these form fields (e.g. clear filters)
    // - start date is not valid (greater than min/max)
    // - end date is not valid (greater than min/max)
    return !(this.dateFormGroup.invalid || this.dateFormGroup.pristine || this.dateFormGroup.controls.startDate.invalid || this.dateFormGroup.controls.endDate.invalid);

  }
}

export const startDateGreaterThanEndDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const startDate = control.get('startDate');
  const endDate = control.get('endDate');
  return startDate && endDate && endDate.value < startDate.value ? {startGreaterThanEnd: true} : null;
};

export class GroupAndControlMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.invalid || form.invalid;
  }
}
