import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CaregiverPerformanceGridDatasource } from './caregiver-performance-grid.datasource';
import { CaregiverManagementDashboardService } from '../../../services/caregiver-management-dashboard.service';
import {first, fromEvent, Observable, ReplaySubject} from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, map } from 'rxjs/operators';
import { ApiSortDirection } from '../../../models/pagination';
import { FilterService } from '../../../services/filter.service';
import { UserPreferenceService } from '../../../services/user-preference.service';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user';
import {CaregiverDto, TurnoverRiskStratificationDescriptions} from "../../../models/caregiver-dto";
import {FeatureFlagService} from "../../../services/feature-flag.service";

@UntilDestroy()
@Component({
  selector: 'app-caregiver-performance-grid',
  templateUrl: './caregiver-performance-grid.component.html',
  styleUrls: ['./caregiver-performance-grid.component.scss']
})
export class CaregiverPerformanceGridComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [];

  @Output() allowExportToCsv = new EventEmitter<boolean>();

  @ViewChild('sortCaregivers', {static: true}) sort: MatSort | null = null;

  @Output() sortChanged = new EventEmitter<MatSort>();

  @ViewChild(MatPaginator, {static: true})
  paginator: MatPaginator | null = null;

  @ViewChild('tableContainer')
  tableContainer: ElementRef<HTMLDivElement> | null = null;

  dataSource: CaregiverPerformanceGridDatasource;
  user: User;
  turnoverRiskPopoverEnabled$: Observable<boolean>;

  private tableWidth: number;
  private gridParameterChanges$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(
    private caregiverManagementDashboardService: CaregiverManagementDashboardService,
    private authService: AuthService,
    private router: Router,
    private filterService: FilterService,
    private userPreferenceService: UserPreferenceService,
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.dataSource = new CaregiverPerformanceGridDatasource(caregiverManagementDashboardService);
    this.turnoverRiskPopoverEnabled$ = featureFlagService.get("tipc_dash_grid_turnover_risk_factors_popover")
      .pipe(first(), map(flagVarient => flagVarient === 'on'))
  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Rows per page:';
    this.initializeColumnDisplaySubscription();
    this.initializeCombinedSubscription();
    this.dataSource.totalCount$.subscribe((totalCount) => {
      this.allowExportToCsv.emit(totalCount !== 0);
    });
    this.sort.sortChange.subscribe(() => {
      this.sortChanged.emit(this.sort);
    });
    this.userService.user$.subscribe(user => this.user = user);
  }

  ngAfterViewInit(): void {
    // Subscribe to window resize events, so we can alter display columns as needed
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this), debounceTime(100))
      .subscribe(() => this.updateTableWidth());

    // Defer to next tick to avoid changing displayedColumns expression in middle of view rendering
    setTimeout(() => this.updateTableWidth())
  }

  loadListPage() {
    const sortDirection = this.sort?.direction || ApiSortDirection.Ascending;
    this.dataSource.loadList(this.filterService.currentSelections, {
      page: (this.paginator?.pageIndex ?? 0),
      pageSize: this.paginator?.pageSize ?? 20,
      sortBy: this.sort?.active,
      sortDirection: sortDirection as ApiSortDirection,
    });
    this.sortChanged.emit(this.sort);
  }

  private updateTableWidth(): void {
    this.tableWidth = this.tableContainer?.nativeElement.clientWidth ?? 0;
  }

  private initializeColumnDisplaySubscription() {
    this.userPreferenceService.userPreferences$.pipe(
      map(userPreferences => userPreferences.caregiverDashboardColumns
        .filter(cdc => !cdc.hideColumn)
        .map(cdc => cdc.column)
      )).subscribe(columnsToDisplay => this.displayedColumns = columnsToDisplay)

  }

  private initializeCombinedSubscription() {
    this.paginator?.page.subscribe(() => this.gridParameterChanges$.next(true));
    this.sort?.sortChange.subscribe(() => {
      this.paginator?.firstPage();
      this.gridParameterChanges$.next(true);
    });
    this.filterService.currentSelectionsChange$.subscribe(() => this.gridParameterChanges$.next(true));
    this.gridParameterChanges$.pipe(debounceTime(100)).subscribe(() => this.loadListPage());
  }
}
