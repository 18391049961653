import { Environment } from './types';

export const environment: Environment = {
  production: false,
  apiURL:
    'https://teaminsights-pc-web-api-service.carepoint-stage-app.prj.gcp.wellsky.io/api/v1',
  authentication: {
    clientId: 'ci-ui-ai-development',
    authServerId: 'aussyy6p7tqLFjziZ1d6',
    redirectUri: '/login/callback',
    scopes: [
      'openid',
      'profile',
      'email',
      'offline_access',
      'api.wellsky.ai.ci.roles.development',
      'api.wellsky.ai.vbi.roles.dev',
    ],
  },
  ciRoleClaimName: 'ai.ci.roles.development',
  vbiRoleClaimName: 'ai.vbi.roles.dev',
  refreshURL: `https://app.clearcareonline.com/teaminsights/refresh?token=`,
  loginRedirectURL: 'https://app.clearcareonline.com/?return_to=teaminsights',
  wsPcDomain: 'clearcareonline.com',
  avaSDKUrl: 'https://sdk-demo.joinava.com/main.js'
};
