import { Component } from '@angular/core';
import { LineChartSettingsService } from '../line-chart.settings.service';
import {GoalCompletionRateSeriesService} from "./goal-completion-rate-series.service";

@Component({
  selector: 'app-goal-completion-rate-chart',
  templateUrl: './goal-completion-rate-chart.component.html',
  styleUrls: ['./goal-completion-rate-chart.component.scss'],
})
export class GoalCompletionRateChartComponent {
  constructor(public goalCompletionRateSeriesService: GoalCompletionRateSeriesService, public settings: LineChartSettingsService) {
    this.settings = Object.assign({}, this.settings
      .configureYAxisTitle('Completion Rate (%)')
      .configureYAxisTickFormat((val: number) => `${Math.floor(val * 100.0)}%`)
      .configureYAxisMin(0)
      .configureYAxisMax(1)
      .configureYAxisTickAmount(5)
      .configureTooltip((val: number, opts?: any): string => {
        if (val === null || val === undefined) return 'No Data';
        return `${Math.round(val * 100.00 * 10.0) / 10}%`
      }));
  }
}
