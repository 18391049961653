import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaregiverManagementDashboardComponent } from './caregiver-management-dashboard.component';

export const caregiverManagementDashboardRoutes: Routes = [
  {
    path: '',
    component: CaregiverManagementDashboardComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(caregiverManagementDashboardRoutes)],
  exports: [RouterModule],
})
export class CaregiverManagementDashboardRoutingModule {}
