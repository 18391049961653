import { Component, OnInit } from '@angular/core';
import { FilterService } from '../../../services/filter.service';
import { UserPreferenceService } from '../../../services/user-preference.service';

@Component({
  selector: 'app-caregiver-performance-grid-empty-state',
  templateUrl: './caregiver-performance-grid-empty-state.component.html',
  styleUrls: ['./caregiver-performance-grid-empty-state.component.scss'],
})
export class CaregiverPerformanceGridEmptyStateComponent implements OnInit {
  isActiveSearch: boolean = false;
  isActiveFilter: boolean = false;
  hasColumnsSelected: boolean = true;
  constructor(private filterService: FilterService,
              private userPreferenceService: UserPreferenceService) { }
  ngOnInit(): void {
    this.setIsActiveSearch();
    this.setIsActiveFilter();
    this.filterService.currentSelectionsChange$.subscribe(() => {
      this.setIsActiveSearch();
      this.setIsActiveFilter();
    });

    this.userPreferenceService.userPreferences$.subscribe(userPreference => {
      this.hasColumnsSelected = userPreference.caregiverDashboardColumns.filter(cdc => !cdc.hideColumn).length > 0
    })
  }

  clearFiltersAndSearch() {
    this.filterService.clearAllFilters()
  }

  private setIsActiveSearch(): void {
    const searchCurrentSelection = this.filterService.currentSelections.caregiverSearch;
    if (!searchCurrentSelection) {
      this.isActiveSearch = false;
      return;
    }
    const currentSearchValue = searchCurrentSelection.filterValue;
    this.isActiveSearch = currentSearchValue && currentSearchValue !== '';
  }

  private setIsActiveFilter(): void {
    this.isActiveFilter = this.filterService.currentSelectionsAsChipDisplay.length > 0;
  }
}
