import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-high-hosp-risk',
  templateUrl: './high-hosp-risk.component.html',
  styleUrls: ['./high-hosp-risk.component.scss']
})
export class HighHospRiskComponent implements OnChanges {
  @Input() highHospRiskPercent: number;
  showDefaultDisplay: boolean = true;
  readonly highHospRiskPercentDefaultDisplay: string = '-';
  showProgressBar: boolean = true;
  widthPercent = '0%';

  ngOnChanges(): void {
    this.setShowDefaultDisplay();
  }

  private setShowDefaultDisplay() {
    if (this.highHospRiskPercent === undefined || this.highHospRiskPercent === null) {
      this.showDefaultDisplay = true;      
      this.showProgressBar = false;
    } else {
      this.showDefaultDisplay = false;
      this.widthPercent = this.highHospRiskPercent * 100 + "%"
    }
  }

}
