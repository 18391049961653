<h3 class="caregiver-chart-title">Agency Overview</h3>
<!-- Ava flag enabled -->
<div class="chart-container-row" *ngIf="enableAva$ | async">
  <!-- left column -->
  <div class="carousel-container" data-cy="left-carousel-container">
    <app-carousel>
      <app-carousel-item>
        <app-chart-card [class.no-margin]="!turnoverCard.chartEnabled" #turnoverCard>
          <div chartTitle>Turnover Rate</div>
          <app-turnover-kpi chartKpi></app-turnover-kpi>
          <app-turnover-chart chart></app-turnover-chart>
        </app-chart-card>
      </app-carousel-item>
      <app-carousel-item>
        <app-chart-card [class.no-margin]="!turnoverRiskCard.chartEnabled" #turnoverRiskCard>
          <div chartTitle>Turnover Risk</div>
          <app-turnover-risk-chart chart></app-turnover-risk-chart>
        </app-chart-card>
      </app-carousel-item>
    </app-carousel>
  </div>

  <!-- middle column -->
  <div class="carousel-container" data-cy="middle-carousel-container">
    <app-carousel>
      <app-carousel-item>
        <app-chart-card [class.no-margin]="!tardinessCard.chartEnabled" #tardinessCard>
          <div chartTitle>Tardiness</div>
          <app-tardiness-kpi chartKpi></app-tardiness-kpi>
          <app-tardiness-chart chart></app-tardiness-chart>
        </app-chart-card>
      </app-carousel-item>
      <app-carousel-item>
        <app-chart-card [class.no-margin]="!shiftsCancelledCard.chartEnabled" #shiftsCancelledCard>
          <div chartTitle>Shifts Canceled by Caregiver</div>
          <app-shifts-cancelled-kpi chartKpi></app-shifts-cancelled-kpi>
          <app-shifts-cancelled-chart chart></app-shifts-cancelled-chart>
        </app-chart-card>
      </app-carousel-item>
    </app-carousel>
  </div>

  <!-- right column -->
  <div class="carousel-container" data-cy="right-carousel-container">
    <app-carousel>
      <app-carousel-item>
        <app-chart-card #pointsEarnedRedeemedCard>
            <div chartTitle>Points Earned/Redeemed</div>
            <div avaIcon class="ava-icon">
              <img src="../../../../assets/icons/ava-col-icon.svg">
            </div>
          <app-points-earned-redeemed-kpi chartKpi></app-points-earned-redeemed-kpi>
          <app-points-earned-redeemed-chart chart></app-points-earned-redeemed-chart>
        </app-chart-card>
      </app-carousel-item>
      <app-carousel-item>
        <app-chart-card>
          <div chartTitle>Goal Completion Rate</div>
          <div avaIcon class="ava-icon">
            <img src="../../../../assets/icons/ava-col-icon.svg">
          </div>
          <app-goal-completion-rate-kpi chartKpi></app-goal-completion-rate-kpi>
          <app-goal-completion-rate-chart chart></app-goal-completion-rate-chart>
        </app-chart-card>
      </app-carousel-item>
      <app-carousel-item>
        <app-chart-card>
          <div chartTitle>Referrals Submitted</div>
          <div avaIcon class="ava-icon">
            <img src="../../../../assets/icons/ava-col-icon.svg">
          </div>
          <app-referrals-submitted-kpi chartKpi></app-referrals-submitted-kpi>
          <app-referrals-submitted-chart chart></app-referrals-submitted-chart>
        </app-chart-card>
      </app-carousel-item>
    </app-carousel>
  </div>
</div>

<!-- Ava flag disabled -->
<div class="chart-container-row" *ngIf="(enableAva$ | async) === false">
  <!-- left column -->
  <app-chart-card [class.no-margin]="!turnoverCard.chartEnabled" #turnoverCard>
    <div chartTitle>Turnover Rate</div>
    <app-turnover-kpi chartKpi></app-turnover-kpi>
    <app-turnover-chart chart></app-turnover-chart>
  </app-chart-card>

  <!-- middle column -->
  <app-chart-card [class.no-margin]="!tardinessCard.chartEnabled" #tardinessCard>
    <div chartTitle>Tardiness</div>
    <app-tardiness-kpi chartKpi></app-tardiness-kpi>
    <app-tardiness-chart chart></app-tardiness-chart>
  </app-chart-card>

  <!-- right column -->
  <app-chart-card [class.no-margin]="!shiftsCancelledCard.chartEnabled" #shiftsCancelledCard>
    <div chartTitle>Shifts Canceled by Caregiver</div>
    <app-shifts-cancelled-kpi chartKpi></app-shifts-cancelled-kpi>
    <app-shifts-cancelled-chart chart></app-shifts-cancelled-chart>
  </app-chart-card>
</div>
