<div class="carousel-button-container">
	<!-- <button mat-icon-button (click)="previousItem()">
		<mat-icon>keyboard_arrow_left</mat-icon>
	</button> -->
	<div class="carousel-container">
		<div class="carousel-header"></div>
		<div class="carousel-content-container">
			<div class="carousel-items">
				<ng-content></ng-content>
			</div>
		</div>
		<div class="carousel-item-indicator">
			<div
				(click)="goToIndex(i)"
				*ngFor="let item of carouselItems; let i = index"
				[ngClass]="[
					'carousel-circle',
					i === activeIndex ? 'carousel-filled-circle' : 'carousel-empty-circle'
				]"
			></div>
		</div>
	</div>
	<!-- <button mat-icon-button (click)="nextItem()">
		<mat-icon>keyboard_arrow_right</mat-icon>
	</button> -->
</div>
