import { Component } from '@angular/core';
import { CurrentSelectionDisplay } from '../../../models/current-selection-display';

@Component({
  selector: 'app-filter-toggle-current-selections-container',
  templateUrl: './filter-toggle-current-selections-container.component.html',
  styleUrls: ['./filter-toggle-current-selections-container.component.scss']
})
export class FilterToggleCurrentSelectionsContainerComponent {
  overflowingCurrentSelections: CurrentSelectionDisplay[] = [];
}
