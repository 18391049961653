<div class="chart-card" *ngIf="chartEnabled">
  <div class="chart-card-header">
    <div class="chart-card-title">
      <ng-content select="[chartTitle]"></ng-content>
      <ng-content select="[avaIcon]"></ng-content>
    </div>
    <div class="chart-card-kpi">
      <ng-content select="[chartKpi]"></ng-content>
    </div>
  </div>
  <ng-content select="[chart]"></ng-content>
</div>

