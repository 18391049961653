<button
  mat-stroked-button
  class="text-uppercase manage-columns-button"
  color="accent"
  data-cy="manage-columns-button"
  [matMenuTriggerFor]="menu"
>
  <mat-icon class="material-icons-outlined icon-button">tune</mat-icon>
  Manage Columns
</button>

<mat-menu #menu="matMenu">
  <!-- Wrapped in Scrollable because otherwise the drag and drop doesn't work for items off screen on first load -->
  <!-- https://github.com/angular/components/issues/14273#issuecomment-804685907 -->
  <div cdkScrollable class="checkbox-list">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let column of userPreference?.caregiverDashboardColumns" cdkDrag class="draggable-box">

        <div class="draggable-box-content" (click)="$event.stopPropagation()">
          <mat-checkbox [checked]="!column.hideColumn"
                          (click)="checkAndUpdate(column)">{{column.displayText}} </mat-checkbox>
        </div>
        <div class="draggable-box-drag-indicator">
          <mat-icon>drag_indicator</mat-icon>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
