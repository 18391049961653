<div
  id="app-topnav"
  role="heading"
  aria-level="1"
  class="row no-gutters app-topnav px-3">
  <div
    #topnavIcon
    [ngClass]="{'topnav-icon-container align-self-center': topnavIconPresent}">
    <ng-content select="[app-topnav-icon], [TopnavIcon]"></ng-content>
  </div>
  <div class="topnav-logo-container align-self-center">
    <ng-content select="[app-topnav-logo], [TopnavLogo]"></ng-content>
  </div>
  <div class="topnav-action-container align-self-center">
    <ng-content select="[app-topnav-action], [TopnavAction]"></ng-content>
  </div>
</div>