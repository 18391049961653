<div
  [class.filter-drawer-navbar-backdrop-open]="filterDrawerService.opened"
  [class.filter-drawer-navbar-backdrop-closed]="!filterDrawerService.opened"
  class="filter-drawer-navbar-backdrop"></div>
<app-topnav class="navbar">
  <div app-topnav-logo>
    <div class="d-flex align-item-center">
      <div class="mt-1">
        <img src="assets/wellsky-logo.svg" width="160px" alt="WellSky Logo" />
      </div>
      <div class="app-title me-auto">TeamInsights for Personal Care</div>
      <div class="user-full-name" data-cy="loggedInUser">
        {{ userFullName | titlecase }}
      </div>
      <div app-topnav-action>
        <nav mat-tab-nav-bar>
          <mat-tab-link routerLink="/population" *ngIf="hasUserRole">
            Caregiver Performance
          </mat-tab-link>
        </nav>
      </div>

      <div class="mt-1" *ngIf="userIsLoggedIn">
        <button [matMenuTriggerFor]="menu">
          <button mat-icon-button aria-label="Account Circle Icon Button">
            <mat-icon>account_circle</mat-icon>
          </button>
        </button>

        <mat-menu #menu="matMenu">
          <ng-container *ngIf="userIsLoggedIn">
            <ng-container *ngIf="hasAdminRole">
              <ng-template mat-menu-item>
                <a [routerLink]="['/admin/users']">Manage users</a>
              </ng-template>
              <ng-template mat-menu-item [divider]="true">
                <a [routerLink]="['/admin/organization']"
                  >Manage organization</a
                >
              </ng-template>
            </ng-container>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>
</app-topnav>
<div class="navigation-bar" *appFeatureFlag="'tipc_enable_ava'">
  <a class="nav-link"
      mat-tab-link
      #rla1="routerLinkActive"
      routerLink="/CaregiverManagementDashboard"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="['is-active']"
    >
      <span>Performance</span>
  </a>
  <a class="nav-link"
      mat-tab-link
      #rla2="routerLinkActive"
      routerLink="/TeamEngage"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLinkActive]="['is-active']"
    >
      <span>TeamEngage</span>
  </a>
</div>