import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-utilization-display',
  templateUrl: './utilization-display.component.html',
  styleUrls: ['./utilization-display.component.scss']
})
export class UtilizationDisplayComponent implements OnChanges {

  @Input() hoursWorked: number | null | undefined;
  @Input() hoursWanted: number | null | undefined;
  utilization: number | null;
  showWanted: boolean = false;
  showWorked: boolean = false;
  showUtilization: boolean = false;

  ngOnChanges(): void {
    if (this.hoursWorked === null || this.hoursWorked === undefined) return;
    this.showWorked = true;
    this.utilization = this.calculateUtilization(this.hoursWorked, this.hoursWanted);
    this.showWanted = this.shouldShowWanted(this.hoursWanted);
    this.showUtilization = this.shouldShowUtilization(this.hoursWanted);
  }

  private calculateUtilization(hoursWorked: number | null | undefined, hoursWanted: number | null | undefined): number | null {
    if (hoursWanted === null || hoursWanted === undefined) return null;
    return this.hoursWorked / this.hoursWanted;
  }

  private shouldShowWanted(hoursWanted: number | null | undefined): boolean {
    return !(hoursWanted === null || hoursWanted === undefined);
  }

  private shouldShowUtilization(hoursWanted: number | null | undefined): boolean {
    return !(hoursWanted === null || hoursWanted === undefined);
  }
}
