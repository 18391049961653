<mat-chip-listbox class="chip-list-fixed-height"
  aria-label="Current Filter Selections">
  <mat-chip
    *ngFor="let currentSelection of currentSelectionsDisplay"
    class="mat-standard-chip"
    (removed)="filterService.removeFilterSelectionByDisplay(currentSelection)">
    {{currentSelection.filterKeyLabel}}: {{currentSelection.filterValueLabel}}
    <button matChipRemove>
      <mat-icon [attr.data-cy]="'chip-remove-button-' + currentSelection.filterKey + '-' + currentSelection.filterValue">
        cancel
      </mat-icon>
    </button>
  </mat-chip>
</mat-chip-listbox>
