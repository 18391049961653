import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { FilterDrawerService } from '../services/filter-drawer.service';

@Component({
  selector: 'app-caregiver-management-dashboard',
  templateUrl: './caregiver-management-dashboard.component.html',
  styleUrls: ['./caregiver-management-dashboard.component.scss'],
})
export class CaregiverManagementDashboardComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSidenav) filterDrawer: MatSidenav | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public filterDrawerService: FilterDrawerService) {
  }

  ngOnInit() {
    this.handleTokenFromUrl();
    this.subscribeToFilterDrawerChanges();
  }

  ngAfterViewInit() {
    this.registerDrawer();
  }

  private handleTokenFromUrl(): void {
    const tokenValue = this.route.snapshot.queryParamMap.get('token');
    if (tokenValue) {
      this.authService.setAccessToken(tokenValue);
      this.router.navigate(['.'], {relativeTo: this.route, queryParams: {}});
    }
  }

  private registerDrawer() {
    this.filterDrawerService.register(this.filterDrawer);
  }

  private subscribeToFilterDrawerChanges(): void {
    this.filterDrawerService.toggleChange$.subscribe(()  => this.filterDrawer.toggle());
  }
}
