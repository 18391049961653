import { Component, OnInit } from '@angular/core';
import { ChangeIndicator, Direction } from '../../../models/change-indicator';
import { TurnoverService } from '../../../services/turnover.service';

@Component({
  selector: 'app-turnover-kpi',
  templateUrl: './turnover-kpi.component.html',
  styleUrls: ['./turnover-kpi.component.scss']
})
export class TurnoverKpiComponent implements OnInit {
  improved: boolean = false;
  steady: boolean = false;
  worsened: boolean = false;

  changeIndicator: ChangeIndicator;
  constructor(private turnoverService: TurnoverService) {
  }

  ngOnInit() {
    this.turnoverService.mostRecentChangeIndicator$.subscribe(changeIndicator => {
      this.changeIndicator = changeIndicator;
      this.initDefaultChangeIndicators();
      switch(changeIndicator.direction) {
        case Direction.up:
          this.worsened = true;
          break;
        case Direction.steady:
          this.steady = true;
          break;
        case Direction.down:
          this.improved = true;
          break;
        case Direction.none:
          break;
        default:
          throw new Error(`no state is set for kpi change indicator ${changeIndicator.direction}`);
      }
    });
  }

  private initDefaultChangeIndicators(): void {
    this.worsened = false;
    this.steady = false;
    this.improved = false;
  }
}
