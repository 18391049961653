import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FilterService} from 'src/app/services/filter.service';
import {LineSeriesMetric, SeriesName} from 'src/app/models/graph-series';
import {LineChartServiceBase} from 'src/app/services/line-chart.service.base';

@Injectable({providedIn: 'root'})
export class GoalCompletionRateSeriesService extends LineChartServiceBase {
  constructor(http: HttpClient, filterService: FilterService) {
    super(http, filterService, [
        SeriesName.agency
      ],
      LineSeriesMetric.goalCompletionRate
    );
  }
}
