import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FeatureFlag } from '../models/feature-flag';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class FeatureFlagService {
  private _featureFlags: Array<FeatureFlag>;
  private _initialized$: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(private httpClient: HttpClient) {
    this.fetchFeatureFlags();
  }

  get(flagKey: string): Observable<string> {
    return this._initialized$.pipe(
      map(() => {
        return this.retrieveFlagByKey(this._featureFlags, flagKey);
      })
    );
  }

  private fetchFeatureFlags(): void {
    this.httpClient
      .get<FeatureFlag[]>(
        `${environment.apiURL}/featureflagspc`
      )
      .pipe(
        map((response) => {
          const flags = response;
          this.cacheFlags(flags);
          return flags;
        }),
        tap(() => this._initialized$.next())
      )
      .subscribe();
  }

  private retrieveFlagByKey(flags: Array<FeatureFlag>, flagKey: string) {
    if (!flags) {
      return 'no-feature-flag-found';
    }
    const flag = flags.find((f) => f.flagKey === flagKey);
    if (!flag) {
      return 'no-feature-flag-found';
    }
    return flag.variantKey;
  }

  private cacheFlags(flags: Array<FeatureFlag>) {
    this._featureFlags = flags;
  }
}
