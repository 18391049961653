import { Component, Input } from '@angular/core';
import { FilterService } from '../../../services/filter.service';
import { CurrentSelectionDisplay } from '../../../models/current-selection-display';

@Component({
  selector: 'app-filter-chip-overflow',
  templateUrl: './filter-chip-overflow.component.html',
  styleUrls: ['./filter-chip-overflow.component.scss']
})
export class FilterChipOverflowComponent {
  @Input() overflowingCurrentSelections: CurrentSelectionDisplay[] = [];

  constructor(public filterService: FilterService) {
  }
}
