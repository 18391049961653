import { Component } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Component({
  selector: 'app-filter-clear-all-button',
  templateUrl: './filter-clear-all-button.component.html',
  styleUrls: ['./filter-clear-all-button.component.scss']
})
export class FilterClearAllButtonComponent {

  constructor(public filterService: FilterService) {
  }
}
