import { Component } from '@angular/core';
import { LineChartSettingsService } from '../line-chart.settings.service';
import { PointsEarnedRedeemedService } from './points-earned-redeemed-service';

@Component({
  selector: 'app-points-earned-redeemed-chart',
  templateUrl: './points-earned-redeemed-chart.component.html',
  styleUrls: ['./points-earned-redeemed-chart.component.scss'],
})
export class PointsEarnedRedeemedChartComponent {
  constructor(public pointsEarnedRedeemedService: PointsEarnedRedeemedService, public settings: LineChartSettingsService) {
    this.settings = Object.assign({}, this.settings
      .configureYAxisTitle('Points')
      .configureYAxisMin((min) => {
        return min = 0
      })
      .configureYAxisTickAmount(6)
      .configureStrokeDashArray([0, 0])
      .configureMarker([4, 4], ['#fff', '#fff'], ['#97449C', '#7AD1FB'])
      .configureTooltip((val: number, opts?: any): string => {
        if (val === null || val === undefined) return 'No Data';
        return `${Math.round(val * 10) / 10} points`
      }));


  }
}
