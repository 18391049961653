import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-canceled-shifts',
  templateUrl: './canceled-shifts.component.html',
  styleUrls: ['./canceled-shifts.component.scss']
})
export class CanceledShiftsComponent implements OnChanges {
  @Input() shiftCancelPercent: number;
  showDefaultDisplay: boolean = true;
  readonly shiftCancelPercentDefaultDisplay: string = '-';
  isAboveThreshold: boolean = false;

  ngOnChanges(): void {
    this.setShowDefaultDisplay();
    this.setIsAboveThreshold();
  }

  private setShowDefaultDisplay() {
    if (this.shiftCancelPercent === undefined || this.shiftCancelPercent === null) {
      this.showDefaultDisplay = true;
    } else {
      this.showDefaultDisplay = false;
    }
  }

  private setIsAboveThreshold() {
    if (this.shiftCancelPercent > 999) {
      this.isAboveThreshold = true;
    }
  }

}
