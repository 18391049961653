import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FilterService } from '../../../services/filter.service';
import { CurrentSelectionDisplay } from '../../../models/current-selection-display';
import { MatChipSet } from '@angular/material/chips';

@Component({
  selector: 'app-filter-current-selections-display',
  templateUrl: './filter-current-selections-display.component.html',
  styleUrls: ['./filter-current-selections-display.component.scss']
})
export class FilterCurrentSelectionsDisplayComponent implements OnInit {
  @Output() overflowingChips: EventEmitter<CurrentSelectionDisplay[]> = new EventEmitter<CurrentSelectionDisplay[]>();
  @ViewChild(MatChipSet, {read: ElementRef}) MatChipSet: ElementRef<HTMLElement>;
  currentSelectionsDisplay: CurrentSelectionDisplay[];

  constructor(public filterService: FilterService) {
  }

  ngOnInit(): void {
    this.currentSelectionsDisplay = this.filterService.currentSelectionsAsChipDisplay;
    this.filterService.currentSelectionsChange$.subscribe(() => {
        this.currentSelectionsDisplay = this.filterService.currentSelectionsAsChipDisplay;
        this.setOverflowingChips();
      }
    )
  }

  // In the future if we don't want the overflow to have all selections and only the ones
  // that don't fit. Need to implement the logic based on the chip elements overflow
  private setOverflowingChips(): void {
    // this.overflowingChips.next(this.atlasChipListElement?.nativeElement?.scrollHeight > 40);
    this.overflowingChips.emit(this.currentSelectionsDisplay);
  }
}
