import { Component, Input, OnChanges } from '@angular/core';
import { TardyMinuteDay } from '../../../models/tardy-minute-day';
import { FilterService } from '../../../services/filter.service';

@Component({
  selector: 'app-tardiness-display',
  templateUrl: './tardiness-display.component.html',
  styleUrls: ['./tardiness-display.component.scss']
})
export class TardinessDisplayComponent implements OnChanges {
  @Input() tardyMinutesAverage: number;
  @Input() tardyMinutesAverageTrend: TardyMinuteDay[] = [];
  maxHeight = 12;
  maxWidth = 40;
  data: string[] = [];
  referenceLine: string[] = [];

  constructor(public filterService: FilterService) {
    this.filterService.currentSelectionsChange$.subscribe(() => this.redrawSparklines());
  }

  ngOnChanges(): void {
    this.redrawSparklines();
  }


  private redrawSparklines() {
    this.data = this.getChartLinkedWithMinMax(this.tardyMinutesAverageTrend);
    this.referenceLine = this.generateReferenceLine(
      this.filterService.startDate,
      this.filterService.endDate,
      this.tardyMinutesAverageTrend
    );
  }

  private generateReferenceLine(startDate: Date, endDate: Date, dataSet: TardyMinuteDay[]): string[] {
    let items = [
      {x: startDate, y: 0},
      {x: endDate, y: 0},
    ];
    const maxValue = 20;
    const minValue = -20;
    const list = items.map((value, index) => {
      const newValue =
        Math.floor(
          ((value.y - minValue) / (maxValue - minValue)) * 100 * 100
        ) / 100;
      const adjustedYValue = Math.floor((newValue * this.maxHeight) / 100);
      const adjustedXValue = Math.floor(index * (this.maxWidth / (length + 1)));
      return adjustedXValue + ',' + adjustedYValue;
    });
    return [...list];
  }

  private getChartLinkedWithMinMax(dataSet: TardyMinuteDay[]): string[] {
    if (!dataSet) return [];
    if (dataSet.length === 0) return [];
    dataSet = dataSet.map((ds) => ({
      shiftDate: ds.shiftDate,
      tardyMinutesAverage: this.limitTardinessToAbsolute15(ds.tardyMinutesAverage),
    }));
    const maxValue = 20;
    const minValue = -20;
    if (!this.filterService.endDate || !this.filterService.startDate) {
      return [];
    }
    const list = dataSet
      .filter((value, index) => !!value.shiftDate && value.tardyMinutesAverage !== null && value.tardyMinutesAverage !== undefined)
      .map((value, index) => {

        const domainHeight = maxValue - minValue;
        const chartHeight = this.maxHeight;
        const adjustedYValue = Math.round(100 *  ((maxValue - value.tardyMinutesAverage) * chartHeight) / domainHeight) / 100;
        const domainWidth = +this.filterService.endDate - +this.filterService.startDate;
        const chartWidth = this.maxWidth;
        const shiftDate = new Date(value.shiftDate);
        const adjustedXValue = Math.round(100 * ((+shiftDate - +this.filterService.startDate) * chartWidth) / domainWidth) / 100;
        return adjustedXValue + ',' + adjustedYValue;
      });
    return [...list];
  }

  private limitTardinessToAbsolute15(tardyMinutes: number): number {
    if (tardyMinutes === null || tardyMinutes === undefined) return null;
    if (tardyMinutes > 15) return 15;
    if (tardyMinutes < -15) return -15;
    return tardyMinutes;
  }
}
