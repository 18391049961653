import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
}) 
export class PendoService {
  constructor(private userService: UserService) { }

  /* matching pendo properties from wspc application here
  // https://github.com/clearcare/clearcare/blob/master/templates/fragments/user_engagement_metrics.html#L13
  and here:
  https://github.com/clearcare/clearcare/blob/master/apps/agency/models.py#L1654
   */
  initialize() {
    this.userService.user$.subscribe({
      next: (user) => {
        const visitor = {
          id: user.userId,
          role: this.getMostPrivilegedRole(user.roles)
        };
        const account = {
          id: `${user.subdomain}-${user.agencyId}`,
          name: user.agencyName,
          creationDate: user.creation,
          agency_stage: user.agencyStage,
          // server_name: '',
          franchisor_id: user.franchisorId,
          franchisor_name: user.franchiseName,
          // agency_state: '',
          agency_country: user.country,
          // evv_enabled: '',
          // eclaims_enabled: '',
        };
        window['pendo'].initialize({
          visitor,
          account
        });
      },
      error: (error) => {
        console.error('There was an error initializing pendo: ', error);
      }
    });
  }

  getMostPrivilegedRole(roles: string[]) {
    if (!roles) return 'other';
    if (roles.length === 0) return 'other';
    if (roles.includes('Agency Admin') && roles.includes('Super Admin')) {
      return 'superadmin';
    }
    if (roles.includes('Agency Admin') && roles.includes('Account Owner')) {
      return 'owner';
    }
    if (roles.includes('Agency Admin')) {
      return 'admin';
    }
    return 'other';
  }
}
