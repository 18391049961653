import { Component } from '@angular/core';
import { FilterDrawerService } from '../../../services/filter-drawer.service';

@Component({
  selector: 'app-filter-drawer-toggle',
  templateUrl: './filter-drawer-toggle.component.html',
  styleUrls: ['./filter-drawer-toggle.component.scss']
})
export class FilterDrawerToggleComponent {
  constructor(public filterDrawerService: FilterDrawerService) {
  }
}
