import { Component, OnInit } from '@angular/core';
import { ChangeIndicator, Direction } from '../../../models/change-indicator';
import { PointsEarnedRedeemedService } from '../points-earned-redeemed-chart/points-earned-redeemed-service';

@Component({
  selector: 'app-points-earned-redeemed-kpi',
  templateUrl: './points-earned-redeemed-kpi.component.html',
  styleUrls: ['./points-earned-redeemed-kpi.component.scss']
})
export class PointsEarnedRedeemedKpiComponent implements OnInit {
  pointsEarnedChangeIndicator: ChangeIndicator;
  pointsRedeemedChangeIndicator: ChangeIndicator;
  constructor(private pointsEarnedRedeemedService: PointsEarnedRedeemedService) {
  }

  ngOnInit() {
    this.pointsEarnedRedeemedService.mostRecentChangeIndicatorPointsEarned$.subscribe(changeIndicator => {
      this.pointsEarnedChangeIndicator = changeIndicator;
    });

    this.pointsEarnedRedeemedService.mostRecentChangeIndicatorPointsRedeemed$.subscribe(changeIndicator => {
      this.pointsRedeemedChangeIndicator = changeIndicator;
    });
  }
}
