import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
  selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {

  @Input() appFeatureFlag: string;
  @Input('appFeatureFlagInverseLogic') inverseLogic: boolean = false;
  isEnabled = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService
  ) {}
  ngOnInit() {
    if (!this.appFeatureFlag) {
      throw new Error(
        '*featureFlag must be given a parameters for flagKey'
      );
    }

    this.featureFlagService
      .get(this.appFeatureFlag)
      .subscribe((flagValue) => {
        if ((flagValue === 'on' && !this.inverseLogic) || (flagValue !== 'on' && this.inverseLogic)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isEnabled = true;
        } else {
            this.viewContainer.clear();
            this.isEnabled = false;
        }
      });
  }
}

