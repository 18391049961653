<mat-form-field appearance="fill" class="filter-location-form-field mat-form-field w-100">
  <mat-label>Location</mat-label>
  <mat-select
    [formControl]="locationFilterFormControl"
    [multiple]="true"
    (selectionChange)="updateLocationFilter()"
    data-cy="location-filter"
  >
    <mat-option *ngFor="let option of locationData" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>