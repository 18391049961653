import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newRequest = this.addAuthenticationToken(request);
    return next.handle(newRequest).pipe(
      catchError((error: any) => {
        const refreshFailed = this.errorHappenedOnRefresh(request);
        if (refreshFailed) {
          return this.handleErrorOnRefresh(error);
        }

        const errorNotRelatedToAuthorization = this.errorNotRelatedToAuthorization(error);
        if (errorNotRelatedToAuthorization) {
          return throwError(error);
        }

        return this.handleUnauthorizedErrors(request, next, error);
      }));
  }

  private handleUnauthorizedErrors(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap((rts: any) => {
          return next.handle(this.addAuthenticationToken(request));
        })
      );
    }
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);
    return this.auth.authorize().pipe(
      switchMap((token: string) => {
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(token);
        return next.handle(this.addAuthenticationToken(request));
      }),
      catchError(this.handleErrorOnRefresh)
    );
  }

  private addAuthenticationToken(request) {
    const accessToken = this.auth.getAccessToken();
    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  private handleErrorOnRefresh(error: any): Observable<never> {
    this.refreshTokenInProgress = false;
    this.auth.logout();
    return throwError(error);
  }

  private errorHappenedOnRefresh(request: HttpRequest<any>): boolean {
    return request.url.includes('refresh');
  }

  private errorNotRelatedToAuthorization(error: HttpErrorResponse | any): boolean {
    return error.status >= 400 && error.status !== 401;
  }
}
