<mat-form-field appearance="fill" class="filter-turnover-risk-form-field mat-form-field w-100">
  <mat-label>Turnover Risk</mat-label>
  <mat-select
    [formControl]="formControl"
    [multiple]="true"
    (selectionChange)="updateTurnoverRiskFilter()"
    data-cy="turnover-risk-filter"
  >
    <mat-option *ngFor="let option of turnoverRiskOptions" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>