import { Component, Input, OnChanges } from '@angular/core';
import { LineChartSettingsService } from '../line-chart.settings.service';
import { FeatureFlagService } from '../../../services/feature-flag.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss'],
  providers: [LineChartSettingsService]
})
export class ChartCardComponent implements OnChanges {
  // Feature flag was causing chart to init properly
  // When used inside the chart container component
  // Due to the timing of content projection
  @Input() featureFlag: string;

  chartEnabled = true;

  constructor(private featureFlagService: FeatureFlagService) {
  }

  ngOnChanges() {
    this.featureFlagService.get(this.featureFlag)
      .pipe(first())
      .subscribe((flagVariant) => {
        if (this.featureFlag)
          this.chartEnabled = flagVariant === 'on';
      })
  }
}
