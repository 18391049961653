import { Component } from '@angular/core';
import { TardinessService } from '../../../services/tardiness.service';
import { LineChartSettingsService } from '../line-chart.settings.service';

@Component({
  selector: 'app-tardiness-chart',
  templateUrl: './tardiness-chart.component.html',
  styleUrls: ['./tardiness-chart.component.scss'],
})
export class TardinessChartComponent {
  constructor(public tardinessService: TardinessService, public settings: LineChartSettingsService) {
    this.settings = Object.assign({}, this.settings
      .configureYAxisTitle('Avg. Mins')
      .configureYAxisMin((min) => {
        if (min > 0) {
          min = 0;
        }
        return min - 2
      })
      .configureYAxisMax((max) => max + 2)
      .configureYAxisTickAmount(6)
      .configureTooltip((val: number, opts?: any): string => {
        if (val === null || val === undefined) return 'No Data';
        return `${Math.round(val * 10) / 10} mins`
      }));


  }
}
