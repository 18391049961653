import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FilterService } from '../../../services/filter.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';
import { CaregiverDto } from '../../../models/caregiver-dto';
import { RedirectService } from '../../../services/redirect.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-caregiver-name-display',
  templateUrl: './caregiver-name-display.component.html',
  styleUrls: ['./caregiver-name-display.component.scss']
})
export class CaregiverNameDisplayComponent implements OnInit, OnChanges {

  @Input() caregiverDto: CaregiverDto;
  @Input() subdomain: string;
  caregiverNameHtml: SafeHtml = '<span></span>';

  constructor(
    private filterService: FilterService,
    private domSanitizer: DomSanitizer,
    private titleCasePipe: TitleCasePipe,
    private redirectService: RedirectService) {
  }

  ngOnInit(): void {
    this.filterService.currentSelectionsChange$.subscribe(() => {
      this.updateLettersToHighlight();
    });
  }

  ngOnChanges() {
    this.updateLettersToHighlight();
  }

  goToCaregiverPage() {
    if (!this.canNavigate) {
      console.warn(
      `could not navigate to WellSky Personal Care Provider Page because required data was missing:
      subdomain=${this.subdomain}
      wsPcDomain=${environment.wsPcDomain}
      caregiverId=${this.caregiverDto?.caregiverId}`);
      return;
    }
    this.redirectService.openNewWindow(window, `https://${this.subdomain}.${environment.wsPcDomain}/caregivers/${this.caregiverDto?.caregiverId}/`);
  }

  get canNavigate() {
    return this.subdomain && environment.wsPcDomain && this.caregiverDto?.caregiverId;
  }

  private updateLettersToHighlight() {
    const searchFilter = this.filterService.currentSelections.caregiverSearch;
    const titleCased = this.titleCasePipe.transform(this.caregiverDto.caregiver);
    if (!searchFilter) {
      this.caregiverNameHtml = this.domSanitizer.bypassSecurityTrustHtml(`<span>${titleCased}</span>`);
      return;
    }
    const searchTerm = searchFilter.filterValue;
    const regexp = new RegExp(searchTerm, 'gi');
    this.caregiverNameHtml = this.domSanitizer.bypassSecurityTrustHtml(
      titleCased.replace(regexp, `<span class="search-highlight">$&</span>`));
  }
}
